import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../componentes/AdminNavbar";
import ModalAdminSalir from "../componentes/ModalAdminSalir";
import IconOne from "../images/icon_1blue.png";
import IconTwo from "../images/icon_2blue.png";
import IconThree from "../images/icon_3blue.png";
import IconFour from "../images/icon_4blue.png";
import IconBack from "../images/icon_flecha_back.png";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import SelectArquetipo, { SelectArquetipoMulti } from "../utils/SelectArq";
import AgregarColor from "../componentes/ModalAdminColor";
import { token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";
import AgregarUbicacion from "../componentes/ModalAdminCrearUbicacion";
import UploadImages from "../componentes/UploadImages";
import Swal from "sweetalert2";
import AdminAlert from "../componentes/AdminResponsiveError";

const MainContainer = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  height: 100%;

  button {
    outline: none;
  }
  input {
    outline: none;
  }
  select {
    outline: none;
  }
  option:disabled {
    display: none;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const ItemForm = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;

  .label {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  select,
  input {
    font-style: italic;
    height: 42px;
    border-radius: 6px;
    background-color: var(--gris-invisible);
    border: none;
  }
  ::placeholder {
    color: black;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 15px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 8px;
  }
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
  }
`;
const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  .empty-img-box {
    display: flex;
    flex-direction: column;
    height: 348px;
    width: 100%;
    text-align: center;
    color: var(--prime-color);
    align-items: center;
    border: none;
    justify-content: center;
    gap: 34px;
    font-family: italic;
    border: 2px dashed var(--gris-medio);
    border-radius: 6px;
  }

  .row-box {
    display: flex;
    height: 348px;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--gris-invisible);
    border: 1px solid var(--prime-color);
    border-radius: 6px;
  }

  .input-container {
    margin-bottom: 12px;

    input {
      border-radius: 50%;
      margin-bottom: 5px;
      width: 10px;
      height: 10px;
      margin: 0px 6px;
      cursor: pointer;
    }
  }
  .add-btn {
    font-weight: 700;
    cursor: pointer;
    width: 160px;
    height: 42px;
    align-items: center;
    justify-content: center;
    text-align: center;
    label {
      padding-top: 8px;
    }
    input {
      display: none;
      cursor: pointer;
    }
  }

  .upload-video {
    width: 160px;
    border: 1px solid var(--prime-color);
  }
  .upload-video:hover {
    border: none;
  }

  .add-btn-box {
    display: flex;
    justify-content: space-between;
    height: 65px;
    align-items: center;
  }

  p {
    font-family: var(--roboto);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 170px;
  }
`;
const FirstRow = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);
  margin-top: 42px;
  margin-bottom: 10px;

  h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 34px;
    margin: 0;
    span {
      color: var(--prime-color);
      font-weight: 400;
    }
  }

  .return-img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  img {
    position: absolute;
    left: 5%;
  }
`;
const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: var(--font-family);
  padding: 0;
  img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 8px;
  }
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: right;
  button {
    width: 162px;
  }
`;
const AgregarProductUsadoScreen = () => {
  //abrir modal salir
  const [isModalSalirOpen, setModalSalirOpen] = useState();
  //abrir modal color
  const [isModalColorOpen, setModalColorOpen] = useState(false);
  //abrir modal location
  const [isModalLocationOpen, setModalLocationOpen] = useState(false);
  //abrir modal video
  const [modalVideo, setModalVideo] = useState();
  // Estado para controlar la visibilidad de las imágenes
  const [showImages, setShowImages] = useState(false);

  // Estado para almacenar las imágenes cargadas
  const [loadedImages, setLoadedImages] = useState([]);
  const [colorReturn, setColorReturn] = useState();
  // Estado para almacenar los videos cargados
  const [loadedVideo, setLoadedVideo] = useState([]);

  //ESTADO DE MARCA - MODELO - VERSION - COlOR - TIPO
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedModelo, setSelectedModelo] = useState("");
  const [modelosArray, setModelosArray] = useState([]);
  const [versionesArray, setVersionesArray] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [destacarImg, setDestacarImg] = useState();
  const [filesUpload, setFilesUpload] = useState([]);
  //recargar los selects que traen opciones de back
  //actualizar los inputs cuando se crea una nueva opcion
  const [reloadKey, setReloadKey] = useState(Date.now());
  const reloadOptions = () => {
    setReloadKey(Date.now());
  };

  const handleDestacarReturn = () => {
    setDestacarImg(!destacarImg);
  };
  //crear color modal
  //manejador de Color
  const handleOpenColor = () => {
    setModalColorOpen(true);
  };
  const handleOpenLocation = () => {
    setModalLocationOpen(true);
  };

  //manejador carga de imagenes
  const handleAddImages = (e) => {
    const files = Array.from(e.target.files);
    filesUpload.push(files[0]);
    setLoadedImages([...loadedImages, ...files]);
    setShowImages(true);
  };

  const handleCreateObjectImages = () => {
    const imageMetadata = loadedImages.map((image) => ({
      url: image.name,
      tipo: image.type,
      // color_id: colorReturn,
      // destacada: destacarImg,
    }));
    console.log("imageMetadata:", imageMetadata);
    setFormulario({
      ...formulario,
      multimedia: imageMetadata,
    });
    console.log("Formulario Multimedia", formulario.multimedia);
    console.log(filesUpload);
    //filesUpload.append('file', )
  };
  useEffect(() => {
    handleCreateObjectImages();
  }, [loadedImages, colorReturn, destacarImg]);

  const handleDeleteImage = (imgName) => {
    const uploadImages = loadedImages.filter((img) => img.name !== imgName);
    setLoadedImages(uploadImages);
  };
  //CARGA DE DATOS DE CADA INPUT
  // Opciones practicas para cada select
  const opciones = {
    category: ["Auto", "SUV", "Camioneta", "Pick-Up", "Utilitario"],
    motor: [
      "1.0",
      "1.2",
      "1.4",
      "1.5",
      "1.6",
      "1.8",
      "2.0",
      "2.2",
      "2.3",
      "2.5",
      "2.8",
      "3.0",
      "3.2",
      "3.4",
      "3.5",
      "4.0",
      "5.0",
      "5.7",
      "6.0",
    ],
    seatMaterial: ["Tela", "Cuero", "Vinilo", "Ante", "Microfibra"],
    tyreMaterial: [
      "Caucho",
      "Caucho Sintetico",
      "Nylon",
      "Kevlar",
      "Poliester",
    ],
    transmission: ["Automática", "Manual", "Hidráulica", "EVT", "CVT", "DSG"],
    traction: ["Delantera", "Trasera", "Total", "4x4"],
    fuel: ["GNC", "Diésel", "Nafta", "Gasoil", "Eléctrico", "Biocombustible"],
    gnc: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    radio: [
      { label: "AM/FM", value: "AM/FM" },
      { label: "No", value: "No" },
    ],
    liftsWindows: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    backupCamera: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    airbag: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    alarm: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    abs: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    parkingSensor: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
    speedControl: [
      { label: "Sí", value: true },
      { label: "No", value: false },
    ],
  };

  const [formulario, setFormulario] = useState({
    domain: "",
    brand: "",
    modelo: "",
    version: "",
    category: "",
    motor: "",
    location: "",
    mileage: "",
    price: "",
    year: "",
    transmission: "",
    traction: "",
    fuel: "",
    doors: "",
    seating: "",
    seatMaterial: "",
    color: "",
    cv: "",
    gnc: "",
    tyreMaterial: "",
    liftsWindows: "",
    backupCamera: "",
    airbag: 0,
    alarm: "",
    abs: "",
    parkingSensor: "",
    speedControl: "",
    radio: "",
    entertainment: "",
    multimedia: [],
  });

  const axiosInstanceWithAuth = useAxiosWithAuth();

  //traer data de modelo
  useEffect(() => {
    const localData = localStorage.getItem("Dominio");
    if (localData) {
      const dominioData = JSON.parse(localData);

      setFormulario({
        ...formulario,
        domain: dominioData,
      });
    }
  }, []);

  //DATOS QUE REGRESAN DE IMGROW
  const handleColorReturn = (value) => {
    setColorReturn({ ...colorReturn, ...value });
  };

  //maneja el boton confirmar
  const modalVideoOpen = () => {
    setModalVideo(true);
  };
  //maneja el boton confirmar
  const closeModalVideo = () => {
    setModalVideo(false);
  };
  const closeModalLocation = () => {
    setModalLocationOpen(false);
  };
  //maneja el boton confirmar
  const closeModalColor = () => {
    setModalColorOpen(false);
  };

  //boton regresar
  const handleReturnButton = () => {
    setModalSalirOpen(true);
  };
  //cerrar boton regresar
  const handleReturnButtonClose = () => {
    setModalSalirOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormulario({ ...formulario, [field]: value });
  };

  const navigate = useNavigate();

  const handleMarcaChange = (selectedOption) => {
    console.log(selectedOption);
    const modelosAvailable = selectedOption.modelos
      ? selectedOption.modelos.map((modelo) => ({
        label: modelo.name,
        _id: modelo._id,
        versions: modelo.versions
          ? modelo.versions.map((version) => ({
            name: version.name,
            _id: version._id,
          }))
          : [],
      }))
      : [];
    setSelectedMarca(selectedOption);
    setFormulario({
      ...formulario,
      brand: selectedOption,
    });
    setModelosArray(modelosAvailable);
    setSelectedModelo("")
    setSelectedVersion("")
  };
  const handleModeloChange = (selectedOption) => {
    const versionsAvailable = selectedOption.versions
      ? selectedOption.versions.map((version) => ({
        label: version.name,
        _id: version._id,
      }))
      : [];

    setSelectedModelo(selectedOption);
    setFormulario({
      ...formulario,
      modelo: selectedOption._id,
    });
    setVersionesArray(versionsAvailable);
    setSelectedVersion("")
  };

  const handleVersionChange = (selectedOption) => {
    setFormulario({
      ...formulario,
      version: selectedOption,
    });
    console.log(formulario);
    setSelectedVersion(selectedOption);
  };

  //Crear tipos:
  const handleCreateCategory = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_CATEGORY,
        {
          name: inputValue,
          isMain: inputValue.isMain || false,
        },
        {
          headers: `Bearer ${token}`,
        }
      );
      const newTipo = {
        label: response.data.name,
        name: inputValue.label,
        isMain: inputValue.isMain || false,
      };
      console.log(response);
      setSelectedCategory(newTipo);
      //reloadOptions();
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  //Crear color:
  const handleCreateColor = async (colorData) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_COLOR,
        {
          name: colorData.name,
          code: colorData.code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newColor = {
        label: response.data.name,
        name: response.data.name,
        _id: response.data._id,
        code: response.data.code,
      };
      console.log(response);
      setSelectedColor(newColor);
    } catch (error) {
      console.error("Error creating new color:", error);
    }
  };
  //Crear ubicacion:
  const handleCreateLocation = async (locationData) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_LOCATION,
        {
          name: locationData.name,
          city: locationData.city,
          number: locationData.number,
          street: locationData.street,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newLocation = {
        label: response.data.name,
        name: response.data.name,
        _id: response.data._id,
      };
      console.log(response);
      setSelectedColor(newLocation);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new location:", error);
    }
  };

  const handleCreateModelo = async (inputValue) => {
    try {
      await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_MODEL,
        {
          name: inputValue,
          brand: selectedMarca.value,
          versions: [],
        }
      ).then(response => {
        const { data } = response;
        const { data: model } = data;

        const newModelo = {
          label: model.name,
          value: model._id,
          name: model.name,
          _id: model._id,
          versions: []
        };
        setModelosArray((prevModelosArray) => [...prevModelosArray, newModelo]);
        setSelectedModelo(newModelo);
        handleModeloChange(newModelo);
        reloadOptions();
      })
    } catch (error) {
      console.error("Error creating new modelo:", error);
    }
  };
  const handleCreateVersion = async (inputValue) => {
    try {
      await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_VERSION,
        {
          name: inputValue,
          modelo: selectedModelo._id,
        }
      ).then(response => {
        const { data } = response
        const { data:version } = data
        
        const newVersion = {
          label: version.name,
          value: version._id,
          name: version.name,
          _id: version._id,
        };
        setVersionesArray((prevVersionesArray) => [...prevVersionesArray, newVersion]);
        setSelectedVersion(newVersion);
        handleVersionChange(newVersion);
        reloadOptions();
      })
      
    } catch (error) {
      console.error("Error creating new version:", error);
    }
  };
  const handleCreateMarca = async (inputValue) => {
    try {
      await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_BRAND,
        {
          name: inputValue,
          modelos: [],
        },
      ).then(response => {
        const { data } = response;
        const { data:brand } = data;

        const newMarca = {
          label: brand.name,
          value: brand._id,
          name: brand.name,
          _id: brand._id,
        };

      setSelectedMarca(newMarca);
      handleMarcaChange(newMarca);
      reloadOptions();
    });
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  const usedCarToApi = (formulario) => {
    return {
      newCar: 0,
      domain: formulario.domain,
      year: formulario.year,
      price: formulario.price,
      doors: formulario.doors,
      seating: formulario.seating,
      mileage: formulario.mileage,
      brand: formulario.brand.value,
      modelo: selectedModelo._id,
      version: formulario.version._id,
      type: formulario.category.value,
      location: formulario.location.value,
      color: formulario.color._id,
      airbag: true,
      gnc: formulario.gnc,
      liftsWindows: formulario.liftsWindows,
      backupCamera: formulario.backupCamera,
      alarm: formulario.alarm,
      abs: formulario.abs,
      parkingSensor: formulario.parkingSensor,
      speedControl: formulario.speedControl,
      motor: formulario.motor,
      cv: formulario.cv,
      traction: formulario.traction,
      transmission: formulario.transmission,
      seatMaterial: formulario.seatMaterial,
      fuel: formulario.fuel,
      tyreMaterial: formulario.tyreMaterial,
      entertainment: formulario.entertainment,
      radio: formulario.radio.value,
    };
  };

  //llamado para crear auto
  const handleCreateVehiculo = async (e) => {
    e.preventDefault();
    console.log("from", formulario)
    console.log("format", usedCarToApi(formulario));
    try {
      await axiosInstanceWithAuth
        .post(ENDPOINTS.CREATE_USED_CAR, usedCarToApi(formulario))
        .then(async ({ data }) => {
          const newCarData = data.data;
          const formData = new FormData();

          if (filesUpload.length > 0) {
            let filesData = [];
            filesUpload.forEach((file, index) => {
              formData.append("files", file);
              if (file.type === "text/plain") {
                filesData.push({
                  url: file.url
                })
              } else {
                filesData.push({
                  favorite: file.favorite,
                  colorId: file.color,
                })
              }
            });
            formData.append("data", JSON.stringify(filesData));

            await axiosInstanceWithAuth
              .post(ENDPOINTS.UPLOAD_MULTIMEDIA(newCarData._id), formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          }
        })
        .then((e) =>
          Swal.fire({
            icon: "success",
            title: "¡Nuevo vehículo añadido al catálogo!",
            iconColor: "#1A3EEA",
            showConfirmButton: false,
            timer: 2000,
          }).then(() => navigate("/admin/home"))
        )
        .catch((err) =>
          Swal.fire({
            icon: "error",
            title: "¡Ha ocurrido un error!",
            iconColor: "#dc3545",
            showConfirmButton: false,
            timer: 2000,
          })
        );
      // navigate("/admin/home")
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  return (
    <>
      <AdminAlert />
      <MainContainer className="container-fluid">
        <Navbar />
        {isModalSalirOpen && (
          <ModalAdminSalir handleReturnButtonClose={handleReturnButtonClose} />
        )}
        {isModalColorOpen && (
          <AgregarColor
            closeModal={closeModalColor}
            onColorAdded={handleCreateColor}
          />
        )}
        {isModalLocationOpen && (
          <AgregarUbicacion
            closeModal={closeModalLocation}
            onLocationAdded={handleCreateLocation}
          />
        )}
        {/*       {modalVideo && <AgregarVideo closeModal={closeModalVideo} />}
         */}{" "}
        <div className="container">
          <form>
            <div className="row">
              <FirstRow>
                <img
                  src={IconBack}
                  alt=""
                  className="return-img"
                  onClick={handleReturnButton}
                />
                <div className="offset-1 col-5">
                  <div className="title">
                    <h1>
                      Nuevo Usado/<span>{formulario.domain}</span>
                    </h1>
                  </div>
                </div>
              </FirstRow>
            </div>
            <div className="row">
              <div className="offset-1">
                <TitleContainer>
                  <img src={IconOne} alt="" className="icon" />
                  <p>Infomación básica</p>
                </TitleContainer>
              </div>
              <StyledContainer>
                <div className="offset-1 col-3">
                  <ItemForm>
                    <SelectArquetipo
                      label="Marca"
                      endpoint={`/api/v1/car/brands`}
                      reload={reloadKey}
                      value={selectedMarca}
                      useDatabaseOptions={true}
                      onCreateOption={handleCreateMarca}
                      onChange={handleMarcaChange}
                    />
                  </ItemForm>
                </div>
                <ItemForm className="col-3">
                  <SelectArquetipo
                    label="Modelos"
                    onCreateOption={handleCreateModelo}
                    reload={reloadKey}
                    value={selectedModelo}
                    useDatabaseOptions={false}
                    presetOptions={modelosArray}
                    onChange={handleModeloChange}
                  />
                </ItemForm>
                <ItemForm className="col-4">
                  <SelectArquetipo
                    label="Versiones"
                    useDatabaseOptions={false}
                    presetOptions={versionesArray}
                    reload={reloadKey}
                    value={selectedVersion}
                    onCreateOption={handleCreateVersion}
                    onChange={handleVersionChange}
                  />
                </ItemForm>
              </StyledContainer>
              <StyledContainer>
                <div className="offset-1 col-1">
                  <ItemForm>
                    <label className="label">Año</label>
                    <input
                      type="number"
                      name="year"
                      value={formulario.year}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          year: parseInt(e.target.value),
                        })
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-2">
                  <ItemForm>
                    <label className="label">Kilometraje</label>
                    <input
                      type="number"
                      value={formulario.mileage}
                      name="mileage"
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          mileage: parseInt(e.target.value),
                        })
                      }
                      placeholder=" Kilometraje..."
                    />
                  </ItemForm>
                </div>
                <ItemForm className="col-3">
                  <SelectArquetipo
                    label="Ubicación"
                    endpoint="/api/v1/car/attributes"
                    useDatabaseOptions={true}
                    reload={reloadKey}
                    onChange={(option) => handleInputChange("location", option)}
                    onCreateOption={handleOpenLocation}
                  />
                </ItemForm>
                <ItemForm className="col-4">
                  <label className="label">Precio</label>
                  <input
                    type="number"
                    value={formulario.price}
                    name="price"
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        price: parseInt(e.target.value),
                      })
                    }
                    placeholder="  Precio..."
                  />
                </ItemForm>
              </StyledContainer>
            </div>

            <div className="row">
              <div className="offset-1">
                <TitleContainer>
                  <img src={IconTwo} alt="2" className="icon" />
                  <p>Datos del vehículo</p>
                </TitleContainer>
              </div>
              <StyledContainer>
                <div className="offset-1 col-3">
                  <ItemForm>
                    <SelectArquetipo
                      label="Combustible"
                      useDatabaseOptions={false}
                      presetOptions={opciones.fuel.map((opcion) => ({
                        label: opcion,
                        value: opcion,
                      }))}
                      onChange={(option) =>
                        handleInputChange("fuel", option.label)
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-3">
                  <ItemForm>
                    <SelectArquetipo
                      label="Traccion"
                      useDatabaseOptions={false}
                      presetOptions={opciones.traction.map((opcion) => ({
                        label: opcion,
                        value: opcion,
                      }))}
                      onChange={(option) =>
                        handleInputChange("traction", option.label)
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-4">
                  <ItemForm>
                    <SelectArquetipo
                      label="Transmision"
                      useDatabaseOptions={false}
                      presetOptions={opciones.transmission.map((opcion) => ({
                        value: opcion,
                        label: opcion,
                      }))}
                      onChange={(selectedOption) => {
                        setFormulario({
                          ...formulario,
                          transmission: selectedOption.label,
                        });
                      }}
                    />
                  </ItemForm>
                </div>
              </StyledContainer>
            </div>
            <div className="row">
              <StyledContainer>
                <div className="offset-1  col-2">
                  <ItemForm>
                    <SelectArquetipo
                      label="Categoria"
                      endpoint="/api/v1/car/attributes"
                      useDatabaseOptions={true}
                      reload={reloadKey}
                      onCreateOption={handleCreateCategory}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setFormulario({
                          ...formulario,
                          category: selectedOption,
                        });
                      }}
                    />
                  </ItemForm>
                </div>
                <ItemForm className=" col-1">
                  <SelectArquetipo
                    presetOptions={opciones.gnc.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    label="GNC"
                    useDatabaseOptions={false}
                    onChange={(option) =>
                      handleInputChange("gnc", option.value)
                    }
                  />
                </ItemForm>

                <ItemForm className="col-3">
                  <SelectArquetipo
                    label="Motor"
                    presetOptions={opciones.motor.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    useDatabaseOptions={false}
                    onChange={(option) =>
                      handleInputChange("motor", option.label)
                    }
                  />
                </ItemForm>
                <ItemForm className="col-4">
                  <label htmlFor="potencia" className="label">
                    Potencia
                  </label>
                  <input
                    type="number"
                    name="potencia"
                    value={formulario.cv}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        cv: e.target.value.toString(),
                      })
                    }
                  />
                </ItemForm>
              </StyledContainer>
            </div>
            <div className="row">
              <div className="offset-1">
                <TitleContainer>
                  <img src={IconThree} alt="3" className="icon" />
                  <p>Apariencia</p>
                </TitleContainer>
              </div>
              <StyledContainer>
                <div className="offset-1 col-2">
                  <ItemForm>
                    <SelectArquetipo
                      label="Color"
                      endpoint="/api/v1/car/color"
                      useDatabaseOptions={true}
                      reload={reloadKey}
                      onChange={(selectedOption) =>
                        setFormulario({
                          ...formulario,
                          color: selectedOption,
                        })
                      }
                      onCreateOption={handleOpenColor}
                    />
                  </ItemForm>
                </div>
                <div className="col-1">
                  <ItemForm className="">
                    <label className="label">Puertas</label>
                    <input
                      type="number"
                      name="doors"
                      value={formulario.doors}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          doors: parseInt(e.target.value),
                        })
                      }
                    />
                  </ItemForm>
                </div>
                <ItemForm className="col-1">
                  <label className="label">Asientos</label>
                  <input
                    type="number"
                    name="seating"
                    value={formulario.seating}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        seating: parseInt(e.target.value),
                      })
                    }
                  />
                </ItemForm>

                <ItemForm className="col-3">
                  <SelectArquetipo
                    label="Mat. Asientos"
                    presetOptions={opciones.seatMaterial.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    useDatabaseOptions={false}
                    onChange={(selectedOption) =>
                      setFormulario({
                        ...formulario,
                        seatMaterial: selectedOption.label,
                      })
                    }
                  />
                </ItemForm>
                <ItemForm className="col-3">
                  <SelectArquetipo
                    label="Llantas"
                    presetOptions={opciones.tyreMaterial.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    useDatabaseOptions={false}
                    onChange={(option) =>
                      handleInputChange("tyreMaterial", option.label)
                    }
                    placeholder="| Seleccionar material"
                  />
                </ItemForm>
              </StyledContainer>
            </div>
            <div className="row">
              <UploadImages
                className={"offset-1 col-4"}
                filesUpload={filesUpload}
                setFilesUpload={setFilesUpload}
              />
            </div>
            <div className="row" style={{ marginTop: "-420px" }}>
              <div className="offset-6">
                <TitleContainer>
                  <img src={IconFour} alt="" className="icon" />
                  <p>Datos Adicionales</p>
                </TitleContainer>
              </div>
              <StyledContainer>
                <div className="offset-6 col-3">
                  <ItemForm>
                    <SelectArquetipo
                      presetOptions={opciones.liftsWindows}
                      useDatabaseOptions={false}
                      label="Levanta Cristales"
                      onChange={(option) =>
                        handleInputChange("liftsWindows", option.value)
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-2">
                  <ItemForm>
                    <SelectArquetipo
                      label="Ctrl. de velocidad"
                      presetOptions={opciones.speedControl}
                      useDatabaseOptions={false}
                      placeholder="| Seleccionar"
                      onChange={(option) =>
                        handleInputChange("speedControl", option.value)
                      }
                    />
                  </ItemForm>
                </div>
              </StyledContainer>
              <StyledContainer>
                <div className="offset-6 col-3">
                  <ItemForm>
                    <SelectArquetipo
                      presetOptions={opciones.parkingSensor}
                      label="Sensor de estacionamiento"
                      useDatabaseOptions={false}
                      placeholder="| Seleccionar"
                      onChange={(option) =>
                        handleInputChange("parkingSensor", option.value)
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-2">
                  <ItemForm>
                    <SelectArquetipo
                      label="CAM de retroceso"
                      presetOptions={opciones.backupCamera}
                      useDatabaseOptions={false}
                      placeholder="| Seleccionar"
                      onChange={(option) =>
                        handleInputChange("backupCamera", option.value)
                      }
                    />
                  </ItemForm>
                </div>
              </StyledContainer>
              <StyledContainer>
                <div className="offset-6 col-1">
                  <ItemForm>
                    <label className="label" htmlFor="airbags">
                      Airbags
                    </label>
                    <input
                      type="number"
                      name="airbags"
                      value={formulario.airbag}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          airbag: parseInt(e.target.value),
                        })
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-1">
                  <ItemForm>
                    <SelectArquetipo
                      presetOptions={opciones.abs}
                      label="Abs"
                      useDatabaseOptions={false}
                      onChange={(option) =>
                        handleInputChange("abs", option.value)
                      }
                      placeholder=""
                    />
                  </ItemForm>
                </div>
                <div className="col-1">
                  <ItemForm>
                    <SelectArquetipo
                      label="Alarma"
                      presetOptions={opciones.alarm}
                      useDatabaseOptions={false}
                      onChange={(option) =>
                        handleInputChange("alarm", option.value)
                      }
                    />
                  </ItemForm>
                </div>
              </StyledContainer>
              <StyledContainer>
                <div className="offset-6 col-2">
                  <ItemForm>
                    <SelectArquetipo
                      presetOptions={opciones.radio}
                      label="Radio"
                      useDatabaseOptions={false}
                      onChange={(option) => handleInputChange("radio", option)}
                      placeholder="| Radio"
                    />
                  </ItemForm>
                </div>
                <div className="col-3">
                  <ItemForm>
                    <label className="label" htmlFor="entretenimiento">
                      Entretenimiento
                    </label>
                    <input
                      type="text"
                      value={formulario.entertainment}
                      placeholder=" Entretenimiento..."
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          entertainment: e.target.value,
                        })
                      }
                    />
                  </ItemForm>
                </div>
              </StyledContainer>
              <div className="container">
                <div className="row">
                  <ButtonContainer className="offset-9 col-2">
                    <button
                      onClick={handleCreateVehiculo}
                      className="primary-btn-big"
                    >
                      Confirmar
                    </button>
                  </ButtonContainer>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="margin-bottom" style={{ height: "50px" }}></div>
      </MainContainer>
    </>
  );
};

export default AgregarProductUsadoScreen;
