import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ComparadorModal from "./ComparadorModal";
import ComparadorCarItem from "./ComparadorCarItem";

const Container = styled.div`
  display: none;
  position: fixed;
  flex-direction: column;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;

  .comparar-btn {
    height: 32px;
    justify-content: center;
    text-align: center;
    padding-top: 4px;
    border: none;
    cursor: pointer;
    margin-top: 2px;

    :disabled {
      color: #595e62;
      background-color: #f3f3f3;
      border-color: transparent;
      font-weight: 500;
    }
  }
  button {
    outline: none;
  }
  .btn-container {
    width: 100%;
    background-color: transparent;
  }
  @media (max-width: 576px) {
    display: block;
  }
`;
const ComparadorContainer = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  height: 126px;
  padding: 15px 0px;
  background-color: #f6f6f6;
  font-family: var(--font-family);
  z-index: 1000;

  .text-box {
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;

    p {
      padding-top: 4px;
    }
  }
  .comparador-empty-car {
    height: 65px;
    width: 100%;
    border-radius: 6px;
    border: 2px dashed var(--prime-color);
    background-color: var(--gris-claro);
  }
  .cars-row {
    display: flex;
    width: 100%;
  }
`;

const BotonAbrirComparador = styled.button`
  width: 100%;
  height: 42px;
  cursor: pointer;
  background: var(--prime-color);
  border-radius: 6px 6px 0px 0px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  border: none;
  outline: none;
  padding-top: 4px;
`;

const ComparadorMobile = ({
  isOpen,
  toggleComparador,
  autosSeleccionados,
  onRemoveCar,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [cantidadAutosSeleccionados, setCantidadAutosSeleccionados] =
    useState(0);

/*   useEffect(() => {
    if (autosSeleccionados) {
      if (autosSeleccionados.length > 2) {
        autosSeleccionados.splice(2, autosSeleccionados.length - 2);
      }
      setCantidadAutosSeleccionados(autosSeleccionados.length);
    }
  }, [autosSeleccionados]); */

  const noCards = cantidadAutosSeleccionados < 2;

  //Abrir Modal Comparador
  const handleModalOpen = () => {
    if (autosSeleccionados && !noCards) setOpenModal(true);
  };
  //cerrar el comparador
  const closeComparadorModal = () => {
    setOpenModal(false);
  };
  return (
    <Container>
      {openModal && (
        <ComparadorModal
          autosSeleccionados={autosSeleccionados}
          closeModal={closeComparadorModal}
        />
      )}
      <BotonAbrirComparador onClick={toggleComparador}>
        {isOpen ? "Cerrar comparador" : "Abrir comparador"}
      </BotonAbrirComparador>
      <div className="container" style={{ backgroundColor: " #f6f6f6" }}>
        <ComparadorContainer $isOpen={isOpen}>
          <div className="text-box">
            <p className="bold">Descubrí la mejor opción.</p>
          </div>
          <div className="row">
            {[...Array(2)].map((_, index) => (
              <div className="col-4" key={index}>
                {autosSeleccionados[index] ? (
                  <ComparadorCarItem
                    auto={autosSeleccionados[index]}
                    onRemove={() => onRemoveCar(index)}
                  />
                ) : (
                  <div className="comparador-empty-car"></div>
                )}
              </div>
            ))}

            <div className="col-4">
              <button
                className="primary-btn comparar-btn"
                onClick={handleModalOpen}
                disabled={noCards}
              >
                Comparar
              </button>
            </div>
          </div>
        </ComparadorContainer>
      </div>
    </Container>
  );
};

export default ComparadorMobile;
