import React from "react";
import banner1 from "../images/HeroBanner.png";
import bannermobile from "../images/HeroBannerMobile.jpg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Banner = styled.div`
  display: flex;
  position: relative;
  margin: 0;
  width: 100%;
  height: 538px;
  background-image: url(${banner1});
  font-family: var(--font-family);
  background-size: cover;
  background-position: center; /* Centra la imagen */
  align-items: center;
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media (max-width: 576px) {
    align-items: end;
    background-image: url(${bannermobile});
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .btn-container {
    display: flex;
    /* align-items: center; */
    width: 100%;
    button {
      outline: none;
    }
  } /*CodePili*/
  .btn-container button {
    width: 100%; /* Los botones ocupan todo el ancho del contenedor */
  } /*CodePili*/

  @media (max-width: 576px) {
    text-align: center;
    margin-bottom: 50px;
    .btn-container {
    }
  }
`;

const BannerInfo = styled.div`
  color: white;
  margin-bottom: 10px;

  h2 {
    font-weight: 700;
    line-height: 20px;
    font-size: 50px;
  }

  p {
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
  }

  @media (max-width: 576px) {
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      font-weight: 700;
      line-height: 22px;
      font-size: 32px;
    }

    p {
      font-weight: 500;
      line-height: 17px;
      font-size: 17px;
    }
  }
`;

const HomeHeroBannerPili = () => {
  const navigate = useNavigate();

  const handleLargeButtonClick = () => {
    navigate("/usados");
  };

  const handleSmallButtonClick = () => {
    navigate("/nuevos");
  };

  return (
    <Banner>
      {" "}
      {/*CodePili*/}
      <div className="container">
        <BannerContent>
          <BannerInfo className="row">
            <h2 className="offset-md-1 col-md-5">Lo hacemos fácil</h2>
          </BannerInfo>
          <BannerInfo className="row">
            <p className="offset-md-1 col-md-4">
              Encontrar tu auto adecuado es una experiencia única con nosotros.
              Comenzá tu búsqueda ahora.
            </p>
          </BannerInfo>
          <div className="row">
            <div className="btn-container">
              <div className="btn-usados offset-md-1 col-md-2">
                {" "}
                {/*CodePili*/}
                <button
                  onClick={handleLargeButtonClick}
                  className="primary-btn-big"
                >
                  USADOS
                </button>
              </div>
              <div className="btn-nuevos col-md-2">
                {" "}
                {/*CodePili*/}
                <button
                  onClick={handleSmallButtonClick}
                  className="primary-btn-reverse-big"
                >
                  NUEVOS
                </button>
              </div>
            </div>
          </div>
        </BannerContent>
      </div>
    </Banner>
  );
};

export default HomeHeroBannerPili;
