import React, { useState } from "react";
import styled from "styled-components";
import "../styles.css";
import { Resend } from "resend";
import Swal from "sweetalert2";
import axios from "axios";
import ENDPOINTS from "../apiEndpoints";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;

const ModalContainer = styled.div`
  width: 490px;
  height: 424px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top: 22px solid var(--prime-color);
  border-radius: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1001; /* Colocar encima del overlay */

  button {
    background-color: var(--prime-color);
    color: white;
    border-radius: 6px;
    height: 32px;
    width: 195px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none;
    line-height: 20px;
    transition: all 0.3s ease-in-out;
  }
  button:hover {
    background-color: #595e62;
  }
  p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 0px 45px;
  }

  input {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 400;
    border: 2px solid var(--gris-claro);
    border-radius: 6px;
    width: 191px;
    height: 45px;
    padding: 10px;
    margin: 10px;
    outline: none;
  }

  .message {
    width: 400px;
    height: 75px;

    padding-bottom: 35px;
  }

  form {
    /* height: 100%;
    flex-wrap: wrap;
    align-items: center;
    text-align: start; */
  }

  @media (max-width: 576px) {
    width: 264px;
    height: 506px;
    font-size: 13px;
    line-height: 13px;

    p {
      padding: 0px 15px;
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
    }
    .message {
      max-width: 190px;
      height: 80px;

      padding-bottom: 35px;
    }
  }
`;

const ModalMeInteresa = ({ closeModal, car }) => {
  const [formulario, setFormulario] = useState({
    name: "",
    codPostal: "",
    email: " ",
    tel: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      fox_api_key: process.env.REACT_APP_FOX_API_KEY || 'ABCDE-FGHIJ-KLMNO',
      fox_api_origin: process.env.REACT_APP_FOX_API_ORIGIN || 'ads',
      fox_api_business_type_id: 1,
      fox_api_first_name: formulario.name,
      fox_api_last_name: '',
      fox_api_phone: formulario.tel,
      fox_api_cellphone: formulario.tel,
      fox_api_email: formulario.email,
      fox_api_notes: formulario.message || "Sin mensaje",
      fox_api_brand: car.brand.name,
      fox_api_model: car.modelo.name,
      fox_api_country: 'Argentina',
      fox_api_state: 'Buenos Aires',
      fox_api_city: formulario.codPostal,
      fox_api_url: window.location,
      fox_api_dni: '00000000',
      fox_api_vendor: 'Mi nombre de proveedor'
    };

    // Enviar datos del formulario a través de Axios
    axios.post(ENDPOINTS.CRM_API, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      console.log(res)
      const { data } = res
      if (data.error === 1) {
        Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          text: data.message,
          showConfirmButton: false,
          iconColor: '#dc3545',
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '¡Gracias por tu solicitud!',
          text: 'Un asesor se comunicará contigo pronto.',

          iconColor: '#1A3EEA',
          showConfirmButton: false,
          timer: 2000,
        });
        setFormulario({
          name: "",
          email: "",
          codPostal: "",
          tel: "",
          message: ""
        });
        closeModal();
      }

    }).catch(err => {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'No se han podido enviar los datos',
        showConfirmButton: false,
        iconColor: '#dc3545',
        timer: 2000,
      });
    })
  };

  // Función para manejar el cambio en los inputs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [id]: value,
    }));
  };

  return (
    <>
      <Overlay onClick={closeModal} />
      <ModalContainer>
        <div className="container-info">
          <p>
            Dejanos tus datos y recibí toda la información sobre este vehículo
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              placeholder="Nombre Completo"
              required
              autoComplete="none"
              value={formulario.name}
              onChange={handleChange}
            />
            {/* Campo Codigo Postal */}
            <input
              type="text"
              id="codPostal"
              placeholder="Código Postal"
              autoComplete="none"
              required
              value={formulario.codPostal}
              onChange={handleChange}
            />

            <input
              type="email"
              id="email"
              placeholder="Email"
              autoComplete="none"
              value={formulario.email}
              required
              onChange={handleChange}
            />
            {/* Campo Celular */}
            <input
              type="tel"
              id="tel"
              placeholder="Celular"
              autoComplete="none"
              required
              value={formulario.tel}
              onChange={handleChange}
            />

            <input
              type="text"
              id="message"
              placeholder="Mensaje (Opcional)"
              className="message"
              autoComplete="none"
              required
              value={formulario.message}
              onChange={handleChange}
            />

            <div className="row justify-content-center">
              {/* Botón de Envío */}
              <button type="submit">Enviar</button>
            </div>
          </form>
        </div>
      </ModalContainer>
    </>
  );
};

export default ModalMeInteresa;
