import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FlechaCard from "../images/FlechaMenuCatalogo.png";
import CloseIcon from "../images/icon_exitmarca.png";
import "../styles.css";
import axiosInstance from "../utils/ContextAxios";
import { FilterContext, FilterProvider } from "../utils/FilterMobileContext";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Colocar encima de todos los demás elementos */
`;
const Modal = styled.div`
  width: 265px;
  height: 421px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  position: fixed;
  overflow-y: auto;
  /* overflow-y: hidden;
  scroll-behavior: smooth; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1001; /* Colocar encima del overlay */

  .modal-title {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: var(--prime-color);
    color: white;
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 15px;
    font-weight: 700;
    padding-left: 35px;
    padding-right: 18px;
    border-radius: 8px 8px 0px 0px;
    p {
      margin: 0;
    }
    img {
      height: 28px;
      cursor: pointer;
    }
  }
`;

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-family: var(--roboto);
  width: 204px;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
  transition: height 0.3s ease; /* Agrega una transición suave */

  ul {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    height: 100%;
    width: 100%;
    list-style: none;
  }
  li {
    display: flex;
    flex-direction: column;

    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); //referencial */
    max-height: 100%;
    width: 100%;

    padding-bottom: 15px;
  }

  .menu-item {
    font-family: var(--roboto);
    font-size: 13px;
    min-height: 32px;
    width: 100%;
    line-height: 13px;
    font-weight: 400;
    display: flex;
    border-bottom: 1px solid var(--gris-claro);
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 6px;
    padding-top: 8px;
  }

  .sub-menu {
    border: none;
    top: 100%;
    padding-top: 10px;
    display: flex;
    overflow: hidden;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  .filter-section {
    flex-direction: column;
  }

  .filter-option {
    color: #080c0d;
    width: 100%;
    font-size: 13px;
    line-height: 13.5px;
    padding-left: 10px;
    margin: 0;

    input {
      margin-right: 4px;
    }
  }
`;
const categoryTitles = {
  doors: "Cant. de Puertas",
  traction: "Tracción",
  transmission: "Transmisión",
  fuel: "Combustible",
  colors: "Colores",
};

const ModalFiltroMobileNuevos = ({ closeModal, onSelectedValuesChange }) => {
  const { selectedValues, setSelectedValues } = useContext(FilterContext);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedModelos, setSelectedModelos] = useState([]);
  const [expandedItems, setExpandedItems] = useState({
    brands: false,
    modelos: false,
    versions: false,
    fuel: false,
    traction: false,
    transmission: false,
    doors: false,
    colors: false,
  });

  const [availableVersions, setAvailableVersions] = useState([]);

  const [options, setOptions] = useState({
    brands: [],
    modelos: [],
    versions: [],
    doors: [],
    traction: [],
    transmission: [],
    fuel: [],
    colors: [],
  });
  // Simulación de datos locales
  const data = {
    brands: [],
    modelos: [],
    versions: [],
    doors: ["2", "3", "4", "5"],
    traction: ["Delantera", "Trasera", "Total", "4x4"],
    transmission: ["Manual", "Automática"],
    fuel: ["GNC", "Diésel", "Nafta", "Gasoil", "Eléctrico", "Biocombustible"],
    colors: ["Rojo", "Azul", "Blanco", "Verde", "Negro", "Gris", "Amarillo"],
  };

  //carga opciones
  useEffect(() => {
    setOptions({
      brands: data.brands,
      modelos: data.modelos,
      versions: data.versions,
      doors: data.doors,
      traction: data.traction,
      transmission: data.transmission,
      category: data.category,
      fuel: data.fuel,
      colors: data.colors,
    });
  }, []);

  //fetch para las marcas
  useEffect(() => {
    axiosInstance
      .get("/api/v1/car/brands")
      .then((response) => {
        const brands = response.data;
        console.log(response.data);
        setOptions((prevOptions) => ({ ...prevOptions, brands }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleItemClick = (category) => {
    setExpandedItems((prevItems) => ({
      ...prevItems,
      [category]: !prevItems[category],
    }));
  };

  const handleFilterChange = (category, option) => {
    setSelectedValues((prevSelectedValues) => {
      let categoryValues = prevSelectedValues[category] || [];

      // Si la opción ya está seleccionada, la eliminamos; si no, la añadimos
      if (categoryValues.includes(option)) {
        categoryValues = categoryValues.filter((value) => value !== option);
      } else {
        categoryValues = [...categoryValues, option];
      }

      const newSelectedValues = {
        ...prevSelectedValues,
        [category]: categoryValues,
      };

      onSelectedValuesChange(newSelectedValues);
      console.log(newSelectedValues);
      return newSelectedValues;
    });
  };

  const handleMarcaChange = (selectedOption) => {
    setSelectedValues((prevSelectedValues) => {
      const newSelectedValues = { ...prevSelectedValues };

      if (prevSelectedValues.brand?.includes(selectedOption)) {
        newSelectedValues.brand = prevSelectedValues.brand.filter(
          (brand) => brand !== selectedOption
        );
        newSelectedValues.modelo = [];
        newSelectedValues.version = [];
      } else {
        newSelectedValues.brand = [
          ...(prevSelectedValues.brand || []),
          selectedOption,
        ];
        newSelectedValues.modelo = [];
        newSelectedValues.version = [];
      }

      const selectedBrand = options.brands.find(
        (brand) => brand.name === selectedOption
      );

      if (newSelectedValues.brand.includes(selectedOption)) {
        setSelectedBrands((prevSelectedBrands) => [
          ...prevSelectedBrands,
          selectedBrand,
        ]);
      } else {
        setSelectedBrands((prevSelectedBrands) =>
          prevSelectedBrands.filter((brand) => brand.name !== selectedOption)
        );
      }

      onSelectedValuesChange(newSelectedValues);
      console.log(newSelectedValues);
      return newSelectedValues;
    });
  };

  const handleModeloChange = (selectedOption) => {
    setSelectedValues((prevSelectedValues) => {
      const newSelectedValues = { ...prevSelectedValues };
      const modelos = prevSelectedValues.modelo || [];

      if (modelos.includes(selectedOption)) {
        // Si el modelo ya está seleccionado, lo quitamos
        newSelectedValues.modelo = modelos.filter(
          (modelo) => modelo !== selectedOption
        );
      } else {
        // Si el modelo no está seleccionado, lo añadimos
        newSelectedValues.modelo = [...modelos, selectedOption];
      }

      const selectedModelo = options.modelos.find(
        (modelo) => modelo.name === selectedOption
      );

      if (newSelectedValues.modelo.includes(selectedOption)) {
        setSelectedModelos((prevSelectedModelos) => [
          ...prevSelectedModelos,
          selectedModelo,
        ]);
      } else {
        setSelectedModelos((prevSelectedModelos) =>
          prevSelectedModelos.filter((modelo) => modelo.name !== selectedOption)
        );
      }

      onSelectedValuesChange(newSelectedValues);
      console.log(newSelectedValues);

      return newSelectedValues;
    });
  };
  const handleVersionChange = (selectedOption) => {
    setSelectedValues((prevSelectedValues) => {
      const newSelectedValues = { ...prevSelectedValues };
      const versions = prevSelectedValues.version || [];

      if (versions.includes(selectedOption)) {
        // Si la versión ya está seleccionada, la quitamos
        newSelectedValues.version = versions.filter(
          (version) => version !== selectedOption
        );
      } else {
        // Si la versión no está seleccionada, la añadimos
        newSelectedValues.version = [...versions, selectedOption];
      }

      onSelectedValuesChange(newSelectedValues);
      console.log(newSelectedValues);
      return newSelectedValues;
    });
  };

  //acumular modelos
  useEffect(() => {
    if (selectedBrands.length > 0) {
      const allModelos = selectedBrands.reduce((acc, brand) => {
        return [...acc, ...brand.modelos];
      }, []);
      setOptions((prevOptions) => ({ ...prevOptions, modelos: allModelos }));
    }
  }, [selectedBrands]);

  //acumular versiones
  useEffect(() => {
    if (selectedModelos.length > 0) {
      const allVersions = selectedModelos.reduce((acc, modelo) => {
        return [...acc, ...modelo.versions];
      }, []);
      setOptions((prevOptions) => ({ ...prevOptions, versions: allVersions }));
    }
  }, [selectedModelos]);

  console.log("versiones", options.versions);

  const renderFilterOptions = (category) => {
    return options[category].map((option) => {
      const optionName = option.name || option;
      return (
        <div className="filter-option" key={optionName || option._id}>
          <input
            type="checkbox"
            id={`${category}-${optionName}`}
            onChange={() => handleFilterChange(category, optionName)}
            checked={selectedValues[category]?.includes(optionName) || false}
          />
          <label htmlFor={`${category}-${optionName}`}>{optionName}</label>
        </div>
      );
    });
  };

  return (
    <>
      <FilterProvider>
        <Overlay onClick={closeModal} />
        <Modal>
          <div className="modal-title">
            <p>Filtrar</p>
            <img src={CloseIcon} alt="close" onClick={closeModal} />
          </div>
          <StyledMenu>
            <ul>
              <li className="item-brand">
                <div
                  className="menu-item"
                  onClick={() => handleItemClick("brands")}
                >
                  Marca
                  <img
                    src={FlechaCard}
                    alt="Flecha"
                    style={{
                      transform: expandedItems["brands"]
                        ? "rotate(180deg)"
                        : "",
                    }}
                  />
                </div>
                {expandedItems["brands"] && (
                  <div className="sub-menu">
                    <div className="filter-section">
                      {options.brands.map((brand) => (
                        <div className="filter-option" key={brand._id}>
                          <input
                            type="checkbox"
                            id={`brand-${brand.name}`}
                            onChange={() => handleMarcaChange(brand.name)}
                            checked={
                              selectedValues.brand?.includes(brand.name) ||
                              false
                            }
                          />
                          <label htmlFor={`brand-${brand.name}`}>
                            {brand.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              {selectedValues.brand && selectedValues.brand.length > 0 && (
                <>
                  <li className="item-modelo">
                    <div
                      className="menu-item"
                      onClick={() => handleItemClick("modelos")}
                    >
                      Modelo
                      <img
                        src={FlechaCard}
                        alt="Flecha"
                        style={{
                          transform: expandedItems["modelos"]
                            ? "rotate(180deg)"
                            : "",
                        }}
                      />
                    </div>
                    {expandedItems["modelos"] && (
                      <div className="sub-menu">
                        <div className="filter-section">
                          {options.modelos.map((modelo) => (
                            <div className="filter-option" key={modelo._id}>
                              <input
                                type="checkbox"
                                id={`modelo-${modelo.name}`}
                                onChange={() => handleModeloChange(modelo.name)}
                                checked={
                                  selectedValues.modelo?.includes(
                                    modelo.name
                                  ) || false
                                }
                              />
                              <label htmlFor={`modelo-${modelo.name}`}>
                                {modelo.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </li>
                  {selectedValues.modelo &&
                    selectedValues.modelo.length > 0 && (
                      <li className="item-version">
                        <div
                          className="menu-item"
                          onClick={() => handleItemClick("versions")}
                        >
                          Versión
                          <img
                            src={FlechaCard}
                            alt="Flecha"
                            style={{
                              transform: expandedItems["versions"]
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                        {expandedItems["versions"] && (
                          <div className="sub-menu">
                            <div className="filter-section">
                              {options.versions.map((version) => (
                                <div
                                  className="filter-option"
                                  key={version._id}
                                >
                                  <input
                                    type="checkbox"
                                    id={`version-${version.name}`}
                                    onChange={() =>
                                      handleVersionChange(version.name)
                                    }
                                    checked={
                                      selectedValues.version?.includes(
                                        version.name
                                      ) || false
                                    }
                                  />
                                  <label htmlFor={`version-${version.name}`}>
                                    {version.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </li>
                    )}
                </>
              )}
              {Object.keys(categoryTitles).map((category) => (
                <li key={category}>
                  <div
                    className="menu-item"
                    onClick={() => handleItemClick(category)}
                  >
                    {categoryTitles[category]}
                    <img
                      src={FlechaCard}
                      alt="Flecha"
                      style={{
                        transform: expandedItems[category]
                          ? "rotate(180deg)"
                          : "",
                      }}
                    />
                  </div>
                  {expandedItems[category] && (
                    <div className="sub-menu">
                      <div className="filter-section">
                        {renderFilterOptions(category)}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </StyledMenu>
        </Modal>
      </FilterProvider>
    </>
  );
};

export default ModalFiltroMobileNuevos;
