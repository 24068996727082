import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../componentes/AdminNavbar";
import ModalAdminSalir from "../componentes/ModalAdminSalir";
import IconOne from "../images/icon_1blue.png";
import IconTwo from "../images/icon_2blue.png";
import IconThree from "../images/icon_3blue.png";
import IconFour from "../images/icon_4blue.png";
import IconBack from "../images/icon_flecha_back.png";
import "../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import AgregarVideo from "../componentes/ModalAdminAgregarVideo";
import SelectArquetipo, { SelectArquetipoMulti } from "../utils/SelectArq";
import AgregarColor from "../componentes/ModalAdminColor";
import axiosInstance, { BASE_URL, token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";
import AgregarUbicacion from "../componentes/ModalAdminCrearUbicacion";
import UploadImages from "../componentes/UploadImages";
import Swal from "sweetalert2";
import AdminAlert from "../componentes/AdminResponsiveError";

const MainContainer = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  height: 100%;

  button {
    outline: none;
  }
  input {
    outline: none;
  }
  select {
    outline: none;
  }
  option:disabled {
    display: none;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const ItemForm = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;

  .label {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  select,
  input {
    font-style: italic;
    height: 42px;
    border-radius: 6px;
    background-color: var(--gris-invisible);
    border: none;
  }
  ::placeholder {
    color: black;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 15px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 8px;
  }
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);
  margin-top: 42px;
  margin-bottom: 10px;

  h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 34px;
    margin: 0;
    span {
      color: var(--prime-color);
      font-weight: 400;
    }
  }

  .return-img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  img {
    position: absolute;
    left: 5%;
  }
`;
const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: var(--font-family);
  padding: 0;
  img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-right: 8px;
  }
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: right;
  button {
    width: 162px;
  }
`;

//CARGA DE DATOS DE CADA INPUT
// Opciones practicas para cada select
const opciones = {
  category: ["Auto", "SUV", "Camioneta", "Pick-Up", "Utilitario"],
  motor: [
    "1.0",
    "1.2",
    "1.4",
    "1.5",
    "1.6",
    "1.8",
    "2.0",
    "2.2",
    "2.3",
    "2.5",
    "2.8",
    "3.0",
    "3.2",
    "3.4",
    "3.5",
    "4.0",
    "5.0",
    "5.7",
    "6.0",
  ],
  seatMaterial: ["Tela", "Cuero", "Vinilo", "Ante", "Microfibra"],
  tyreMaterial: ["Caucho", "Caucho Sintetico", "Nylon", "Kevlar", "Poliester"],
  transmission: ["Automática", "Manual", "Hidráulica", "EVT", "CVT", "DSG"],
  traction: ["Delantera", "Trasera", "Total", "4x4"],
  fuel: ["GNC", "Diésel", "Nafta", "Gasoil", "Eléctrico", "Biocombustible"],
  gnc: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  radio: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  liftsWindows: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  backupCamera: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  airbag: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  alarm: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  abs: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  parkingSensor: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
  speedControl: [
    { label: "Sí", value: true },
    { label: "No", value: false },
  ],
};

const EditarProductUsadoScreen = () => {
  //producto
  const [producto, setProducto] = useState();
  //abrir modal salir
  const [isModalSalirOpen, setModalSalirOpen] = useState();
  //abrir modal color
  //abrir modal location
  const [isModalLocationOpen, setModalLocationOpen] = useState(false);
  const [isModalColorOpen, setModalColorOpen] = useState(false);
  //abrir modal video
  const [modalVideo, setModalVideo] = useState();
  // Estado para controlar la visibilidad de las imágenes
  const [showImages, setShowImages] = useState(false);

  // Estado para almacenar las imágenes cargadas
  const [loadedImages, setLoadedImages] = useState([]);

  const [filesUpload, setFilesUpload] = useState([]);
    //checkea si se actualizo un dato o no
  const [wasUpdated, setWasUpdated] = useState(false);

  //ESTADO DE MARCA - MODELO - VERSION - COlOR - TIPO
  const [colorReturn, setColorReturn] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedModelo, setSelectedModelo] = useState("");
  const [modelosArray, setModelosArray] = useState([]);
  const [versionesArray, setVersionesArray] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  //actualizar los inputs cuando se crea una nueva opcion
  const [reloadKey, setReloadKey] = useState(Date.now());

  const reloadOptions = () => {
    setReloadKey(Date.now());
  };

  //CARGA DE DATOS DE CADA INPUT
  const [loading, setLoading] = useState(true);

  const [formulario, setFormulario] = useState({
    domain: "",
    brand: "",
    modelo: "",
    version: "",
    category: "",
    motor: "",
    location: "",
    mileage: "",
    price: "",
    year: "",
    transmission: "",
    traction: "",
    fuel: "",
    doors: "",
    seating: "",
    seatMaterial: "",
    color: "",
    cv: "",
    gnc: "",
    tyreMaterial: "",
    liftsWindows: "",
    backupCamera: "",
    airbag: "",
    alarm: "",
    abs: "",
    parkingSensor: "",
    speedControl: "",
    radio: "",
    entertainment: "",
    multimedia: [],
  });

  const mapFormulario = (data) => {
    setFormulario({
      domain: data.domain,
      brand: data.brand
        ? { label: data.brand.name, value: data.brand._id }
        : "",
      modelo: data.modelo
        ? { label: data.modelo.name, value: data.modelo._id }
        : "",
      version: data.version
        ? { label: data.version.name, value: data.version._id }
        : "",
      category: data.category
        ? { label: data.category.name, value: data.category._id }
        : "",
      motor: data.motor ? { label: data.motor, value: data.motor } : "",
      transmission: data.transmission.map((option) => ({
        label: option,
        value: option,
      })),
      traction: data.traction
        ? { label: data.traction, value: data.traccion }
        : "",
      fuel: data.fuel ? { label: data.fuel, value: data.fuel } : "",
      seatMaterial: data.seatMaterial
        ? {
            label: data.seatMaterial,
            value: data.seatMaterial,
          }
        : "",
      location: data.location
        ? {
            label: data.location.name,
            value: data.location._id,
          }
        : "",

      tyreMaterial: data.tyreMaterial
        ? {
            label: data.tyreMaterial,
            value: data.tyreMaterial,
          }
        : "",
      cv: data.cv || "",
      color: data.color
        ? {
            label: data.color.name,
            value: data.color._id,
            code: data.color.code,
          }
        : "",
      warranty: data.warranty || "",
      accessories: data.accessories || "",
      airbag: data.airbag,
      seating: data.seating || "",
      mileage: data.mileage || "",
      year: data.year || "",
      doors: data.doors || "",
      price: data.price || "",
      gnc: data.gnc
        ? { label: data.gnc ? "Si" : "No", value: data.gnc }
        : { label: "No", value: false },
      alarm: data.alarm
        ? { label: data.alarm  ? "Si" : "No", value: data.alarm }
        : { label: "No", value: false },
      radio: data.radio
        ? { label: data.radio ? "Si" : "No", value: data.radio }
        : { label: "No", value: false },
      abs: data.abs
        ? { label: data.abs ? "Si" : "No", value: data.abs }
        : { label: "No", value: false },
      backupCamera: data.backupCamera
        ? {
            label: data.backupCamera ? "Si" : "No",
            value: data.backupCamera,
          }
        : { label: "No", value: false },
      entertainment: data.entertainment || "",
      parkingSensor: data.parkingSensor
        ? {
            label: data.parkingSensor ? "Si" : "No",
            value: data.parkingSensor,
          }
        : { label: "No", value: false },
      liftsWindows: data.liftsWindows
        ? {
            label: data.liftsWindows ? "Si" : "No",
            value: data.liftsWindows,
          }
        : { label: "No", value: false },
      speedControl: data.speedControl
        ? {
            label: data.speedControl ? "Si" : "No",
            value: data.speedControl,
          }
        : { label: "No", value: false },
    });
  }

  const fetchAndConvertToBlob = async (media) => {
    const response = await fetch(BASE_URL + '/' + media.path)
    if (!response.ok) throw new Error("Error fetching")
    return response.blob().then(blob => {
        return { 
          blob:blob, 
          color:media.color,
          name: media.filename,
          mediaId:media._id,
          favorite:media.favorite  
        }
      })
  }
  const { id } = useParams();
  //pedir datos
  useEffect(() => {
    
    const fetchData = async () => {
      await axiosInstance.get(
          ENDPOINTS.GET_USED_CAR_BY_ID(id)
        ).then(async ({ data }) => {
          const { data: car } = data;
          console.log(data)
          setProducto(car);
          console.log("Response", car)

          const imageTypes = ["image/jpeg", "image/png", "image/gif", "text/plain"];
          const imageFiles = car.multimedia.filter((media) =>
            imageTypes.includes(media.mimetype)
          );

          mapFormulario(car);

          return Promise.all(imageFiles.map(file => {
            if(file.filetype === 'video') {
              return {
                  name: file.path,
                  mediaId:file._id,
                  favorite:file.favorite,
                  url:file.path  
                }
            } else {
              return fetchAndConvertToBlob(file)
            }
          }))
        }).then(res => {
          const files = res.map(data => {
            let file;
            console.log(data)
            if (data.blob) {
            const { blob } = data
            file = new File([blob], data.name, { type: blob.type })
            file.color = data.color
            file.id = data.mediaId
            file.favorite = data.favorite
            file.updated = false}
            else{
              file = new File([data.url], "video.txt", {
                type: "text/plain",
              });
              file['url'] = data.url;
              file.updated =false;
              file.mediaId = data.mediaId;
            }
            return file
          })
          setFilesUpload(files)
          console.log("F", files)
        }).catch(console.log("Error"))
        .finally(() => setLoading(false))
    };
    fetchData();
  }, []);

  const axiosInstanceWithAuth = useAxiosWithAuth();


  //maneja el boton confirmar
  const closeModalVideo = () => {
    setModalVideo(false);
  };
  const closeModalLocation = () => {
    setModalLocationOpen(false);
  };
  //maneja el boton confirmar
  const closeModalColor = () => {
    setModalColorOpen(false);
  };

  //boton regresar
  const handleReturnButton = () => {
    setModalSalirOpen(true);
  };
  //cerrar boton regresar
  const handleReturnButtonClose = () => {
    setModalSalirOpen(false);
  };

  const handleInputChange = (field, value) => {
    console.log(field, value);
    console.log(field, value);
    setFormulario({ ...formulario, [field]: value });
  };


  //crear color modal
  //manejador de Color
  const handleOpenColor = () => {
    setModalColorOpen(true);
  };
  const handleOpenLocation = () => {
    setModalLocationOpen(true);
  };

  const handleCreateObjectImages = () => {
    const imageMetadata = loadedImages.map((image) => ({
      url: image.name,
      tipo: image.type,
      // color_id: colorReturn,
      // destacada: destacarImg,
    }));
    console.log("imageMetadata:", imageMetadata);
    setFormulario({
      ...formulario,
      multimedia: imageMetadata,
    });
    console.log("Formulario Multimedia", formulario.multimedia);
    console.log(filesUpload);
    //filesUpload.append('file', )
  };
  useEffect(() => {
    handleCreateObjectImages();
  }, [loadedImages, colorReturn]);

  const navigate = useNavigate();

  const handleMarcaChange = (selectedOption) => {
    console.log(selectedOption);
    const modelosAvailable = selectedOption.modelos
      ? selectedOption.modelos.map((modelo) => ({
          label: modelo.name,
          _id: modelo._id,
          versions: modelo.versions
            ? modelo.versions.map((version) => ({
                name: version.name,
                _id: version._id,
              }))
            : [],
        }))
      : [];
    setSelectedMarca(selectedOption);
    setFormulario({
      ...formulario,
      brand: selectedOption,
    });
    setModelosArray(modelosAvailable);
  };
  const handleModeloChange = (selectedOption) => {
    const versionsAvailable = selectedOption.versions
      ? selectedOption.versions.map((version) => ({
          label: version.name,
          _id: version._id,
        }))
      : [];

    setSelectedModelo(selectedOption);
    setFormulario({
      ...formulario,
      modelo: selectedOption.modelo,
    });
    setVersionesArray(versionsAvailable);
  };

  const handleVersionChange = (selectedOption) => {
    setFormulario({
      ...formulario,
      version: selectedOption,
    });
    console.log(formulario);
    setSelectedVersion(selectedOption);
  };

  //Crear tipos:
  const handleCreateCategory = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_CATEGORY,
        {
          name: inputValue,
          isMain: inputValue.isMain || false,
        },
        {
          headers: `Bearer ${token}`,
        }
      );
      const newTipo = {
        label: response.data.name,
        name: inputValue.label,
        isMain: inputValue.isMain || false,
      };
      console.log(response);
      setSelectedCategory(newTipo);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  //Crear color:
  const handleCreateColor = async (colorData) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_COLOR,
        {
          name: colorData.name,
          code: colorData.code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newColor = {
        label: response.data.name,
        name: response.data.name,
        _id: response.data._id,
        code: response.data.code,
      };
      console.log(response);
      setSelectedColor(newColor);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new color:", error);
    }
  };
  //Crear ubicacion:
  const handleCreateLocation = async (locationData) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_LOCATION,
        {
          name: locationData.name,
          city: locationData.city,
          number: locationData.number,
          street: locationData.street,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newLocation = {
        label: response.data.name,
        name: response.data.name,
        _id: response.data._id,
      };
      console.log(response);
      setSelectedColor(newLocation);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new location:", error);
    }
  };

  const handleCreateModelo = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_MODEL,
        {
          name: inputValue,
          brand: selectedMarca.value,
          versions: [],
        }
      );
      const newModelo = {
        label: response.data.name,
        value: response.data._id,
        name: response.data.name,
        _id: response.data._id,
      };

      setSelectedModelo(newModelo);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new modelo:", error);
    }
  };
  const handleCreateVersion = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_VERSION,
        {
          name: inputValue,
          modelo: selectedModelo._id,
        }
      );
      const newVersion = {
        label: response.data.name,
        value: response.data._id,
        name: response.data.name,
        _id: response.data._id,
      };

      setSelectedVersion(newVersion);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new version:", error);
    }
  };
  const handleCreateMarca = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_NEW_BRAND,
        {
          name: inputValue,
          modelos: [],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newMarca = {
        label: response.data.name,
        value: response.data._id,
        name: response.data.name,
        _id: response.data._id,
      };
      console.log(response);
      setSelectedMarca(newMarca);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  const usedCarToApi = (formulario) => {
    return {
      newCar: 0,
      domain: formulario.domain,
      year: formulario.year,
      price: formulario.price,
      doors: formulario.doors,
      seating: formulario.seating,
      mileage: formulario.mileage,
      brand: formulario.brand.value,
      modelo: selectedModelo._id,
      version: formulario.version._id,
      type: formulario.category.value,
      location: formulario.location.value,
      color: formulario.color.value,
      airbag: true,
      gnc: formulario.gnc.value,
      liftsWindows: formulario.liftsWindows.value,
      backupCamera: formulario.backupCamera.value,
      alarm: formulario.alarm.value,
      abs: formulario.abs.value,
      parkingSensor: formulario.parkingSensor.value,
      speedControl: formulario.speedControl.value,
      motor: formulario.motor.value,
      cv: formulario.cv,
      traction: formulario.traction.label,
      transmission: formulario.transmission.value,
      seatMaterial: formulario.seatMaterial.value,
      fuel: formulario.fuel.value,
      tyreMaterial: formulario.tyreMaterial.value,
      entertainment: formulario.entertainment,
      radio: "AM/FM",
    }
  }

  //llamado para crear auto
  const handleEditVehiculo = async (e) => {
    e.preventDefault();
    console.log(formulario);
    console.log("format",usedCarToApi(formulario));
    const formData = usedCarToApi(formulario);
    try {
      const response = await axiosInstanceWithAuth
        .put(`${ENDPOINTS.UPDATE_USED_CAR}/${id}`, formData)
        .then(async ({ data }) => {
          const newCarData = data.data;
          const formData = new FormData();
          
          const filesToUpdate = filesUpload.filter(file => file.id);
          const filesToCreate = filesUpload.filter(file => !file.id);

          // si filesToUpdate un endpoint  que solo actualice los datos
          if (wasUpdated) {
            await axiosInstanceWithAuth
              .put(ENDPOINTS.UPDATE_MULTIMEDIA(newCarData._id), {
                data: filesToUpdate
              }).then((res) => console.log(res))
          }

          // si filesToCreate endpoint que crea y agrega las imagenes
          if (filesToCreate.length > 0) {
            let filesData=[]
            filesToCreate.forEach((file, index) => {
              formData.append("files", file);
              if (file.type === "text/plain") {
                filesData.push({
                  url: file.url
                })
              } else {
                filesData.push({
                  favorite: file.favorite,
                  colorId: file.color,
                })
              }
            });
            formData.append('data',JSON.stringify(filesData))
              await axiosInstanceWithAuth
                .post(ENDPOINTS.UPLOAD_MULTIMEDIA(newCarData._id), formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((res) => console.log('uploadsuccess',res))
                .catch((err) => console.log(err));
          }
        }).then((e) => Swal.fire({
          icon: 'success',
          title: 'Datos actualizados correctamente',
          iconColor: '#1A3EEA',
          showConfirmButton: false,
          timer: 2000,
        }).then(() => navigate("/admin/home")))
        .catch((err) => Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          iconColor: '#dc3545',
          showConfirmButton: false,
          timer: 2000,
        }));
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  return (
    <MainContainer className="container-fluid">
      <Navbar />
      {isModalLocationOpen && (
        <AgregarUbicacion
          closeModal={closeModalLocation}
          onLocationAdded={handleCreateLocation}
        />
      )}
      {isModalSalirOpen && (
        <ModalAdminSalir handleReturnButtonClose={handleReturnButtonClose} />
      )}
      {isModalColorOpen && (
        <AgregarColor
          closeModal={closeModalColor}
          onColorAdded={handleCreateColor}
        />
      )}
      {modalVideo && <AgregarVideo closeModal={closeModalVideo} />}
      <div className="container">
          <div className="row">
            <FirstRow>
              <img
                src={IconBack}
                alt=""
                className="return-img"
                onClick={handleReturnButton}
              />
              <div className="offset-1 col-5">
                <div className="title">
                  <h1>
                    Usado/<span>{formulario.domain}</span>
                  </h1>
                </div>
              </div>
            </FirstRow>
          </div>
          <div className="row">
            <div className="offset-1">
              <TitleContainer>
                <img src={IconOne} alt="" className="icon" />
                <p>Infomación básica</p>
              </TitleContainer>
            </div>
            <StyledContainer>
              <div className="offset-1 col-3">
                <ItemForm>
                  <SelectArquetipo
                    label="Marca"
                    endpoint={`/api/v1/car/brands`}
                    useDatabaseOptions={true}
                    onCreateOption={handleCreateMarca}
                    onChange={handleMarcaChange}
                    value={formulario.brand}
                  />
                </ItemForm>
              </div>
              <ItemForm className="col-3">
                <SelectArquetipo
                  label="Modelos"
                  onCreateOption={handleCreateModelo}
                  useDatabaseOptions={false}
                  presetOptions={modelosArray}
                  onChange={handleModeloChange}
                  value={formulario.modelo}
                />
              </ItemForm>
              <ItemForm className="col-4">
                <SelectArquetipo
                  label="Versiones"
                  useDatabaseOptions={false}
                  presetOptions={versionesArray}
                  onCreateOption={handleCreateVersion}
                  onChange={handleVersionChange}
                  value={formulario.version}
                />
              </ItemForm>
            </StyledContainer>
            <StyledContainer>
              <div className="offset-1 col-1">
                <ItemForm>
                  <label className="label">Año</label>
                  <input
                    type="number"
                    name="year"
                    value={formulario.year}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        year: parseInt(e.target.value),
                      })
                    }
                  />
                </ItemForm>
              </div>
              <div className="col-2">
                <ItemForm>
                  <label className="label">Kilometraje</label>
                  <input
                    type="number"
                    value={formulario.mileage}
                    name="mileage"
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        mileage: parseInt(e.target.value),
                      })
                    }
                    placeholder=" Kilometraje..."
                  />
                </ItemForm>
              </div>
              <ItemForm className="col-3">
                <SelectArquetipo
                  label="Ubicación"
                  endpoint="/api/v1/car/attributes"
                  value={formulario.location}
                  useDatabaseOptions={true}
                  onChange={(selectedOption) =>
                    setFormulario({
                      ...formulario,
                      location: selectedOption.name,
                    })
                  }
                />
              </ItemForm>
              <ItemForm className="col-4">
                <label className="label">Precio</label>
                <input
                  type="number"
                  value={formulario.price}
                  name="precio"
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      price: parseInt(e.target.value),
                    })
                  }
                  placeholder="  Precio..."
                />
              </ItemForm>
            </StyledContainer>
          </div>

            <div className="row">
              <div className="offset-1">
                <TitleContainer>
                  <img src={IconTwo} alt="" className="icon" />
                  <p>Datos del vehículo</p>
                </TitleContainer>
              </div>
              <StyledContainer>
                <div className="offset-1 col-3">
                  <ItemForm>
                    <SelectArquetipo
                      label="Combustible"
                      useDatabaseOptions={false}
                      value={formulario.fuel}
                      presetOptions={opciones.fuel.map((opcion) => ({
                        label: opcion,
                        value: opcion,
                      }))}
                      onChange={(option) => handleInputChange("fuel", option)}
                    />
                  </ItemForm>
                </div>
                <div className="col-3">
                  <ItemForm>
                    <SelectArquetipo
                      label="Traccion"
                      useDatabaseOptions={false}
                      value={formulario.traction}
                      presetOptions={opciones.traction.map((opcion) => ({
                        label: opcion,
                        value: opcion,
                      }))}
                      onChange={(option) =>
                        handleInputChange("traction", option)
                      }
                    />
                  </ItemForm>
                </div>
                <div className="col-4">
                  <ItemForm>
                    <SelectArquetipo
                      label="Transmision"
                      useDatabaseOptions={false}
                      presetOptions={opciones.transmission.map((opcion) => ({
                        value: opcion,
                        label: opcion,
                      }))}
                      value={formulario.transmission}
                      onChange={(selectedOption) => {
                        setFormulario({
                          ...formulario,
                          transmission: selectedOption,
                        });
                      }}
                    />
                  </ItemForm>
                </div>
              </StyledContainer>
            </div>
            <div className="row">
              <StyledContainer>
                <div className="offset-1  col-2">
                  <ItemForm>
                    <SelectArquetipo
                      label="Categoria"
                      endpoint="/api/v1/car/attributes"
                      useDatabaseOptions={true}
                      reload={reloadKey}
                      onCreateOption={handleCreateCategory}
                      value={formulario.category}
                      onChange={(selectedOption) =>
                        setFormulario({
                          ...formulario,
                          category: selectedOption.name,
                        })
                      }
                    />
                  </ItemForm>
                </div>
                <ItemForm className=" col-1">
                  <SelectArquetipo
                    presetOptions={opciones.gnc.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    value={formulario.gnc}
                    label="GNC"
                    useDatabaseOptions={false}
                    onChange={(option) => handleInputChange("gnc", option)}
                  />
                </ItemForm>

                <ItemForm className="col-3">
                  <SelectArquetipo
                    label="Motor"
                    presetOptions={opciones.motor.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    useDatabaseOptions={false}
                    value={formulario.motor}
                    onChange={(option) => handleInputChange("motor", option)}
                  />
                </ItemForm>
                <ItemForm className="col-4">
                  <label htmlFor="potencia" className="label">
                    Potencia
                  </label>
                  <input
                    type="number"
                    name="potencia"
                    value={formulario.cv}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        cv: parseInt(e.target.value),
                      })
                    }
                  />
                </ItemForm>
              </StyledContainer>
            </div>
            <div className="row">
              <div className="offset-1">
                <TitleContainer>
                  <img src={IconThree} alt="" className="icon" />
                  <p>Apariencia</p>
                </TitleContainer>
              </div>
              <StyledContainer>
                <div className="offset-1 col-2">
                  <ItemForm>
                    <SelectArquetipo
                      label="Color"
                      endpoint="/api/v1/car/color"
                      useDatabaseOptions={true}
                      reload={reloadKey}
                      onChange={(selectedOption) =>
                        setFormulario({
                          ...formulario,
                          color: selectedOption,
                        })
                      }
                      onCreateOption={handleOpenColor}
                      value={formulario.color}
                    />
                  </ItemForm>
                </div>
                <div className="col-1">
                  <ItemForm className="">
                    <label className="label">Puertas</label>
                    <input
                      type="number"
                      name="puertas"
                      min={1}
                      value={formulario.doors}
                      onChange={(e) =>
                        setFormulario({
                          ...formulario,
                          doors: parseInt(e.target.value),
                        })
                      }
                    />
                  </ItemForm>
                </div>
                <ItemForm className="col-1">
                  <label className="label">Asientos</label>
                  <input
                    type="number"
                    name="seating"
                    min={1}
                    value={formulario.seating}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        seating: parseInt(e.target.value),
                      })
                    }
                  />
                </ItemForm>

              <ItemForm className="col-3">
                <SelectArquetipo
                  label="Mat. Asientos"
                  presetOptions={opciones.seatMaterial.map((opcion) => ({
                    label: opcion,
                    value: opcion,
                  }))}
                  value={formulario.seatMaterial}
                  useDatabaseOptions={false}
                  onChange={(selectedOption) =>
                    setFormulario({
                      ...formulario,
                      seatMaterial: selectedOption,
                    })
                  }
                />
              </ItemForm>
              <ItemForm className="col-3">
                <SelectArquetipo
                  label="Llantas"
                  presetOptions={opciones.tyreMaterial.map((opcion) => ({
                    label: opcion,
                    value: opcion,
                  }))}
                  value={formulario.tyreMaterial}
                  useDatabaseOptions={false}
                  onChange={(option) =>
                    handleInputChange("tyreMaterial", option)
                  }
                  placeholder="| Seleccionar material"
                />
              </ItemForm>
            </StyledContainer>
          </div>
          <div className="row">
            <UploadImages 
              className={'offset-1 col-4'}
              filesUpload={filesUpload} 
              setFilesUpload={setFilesUpload}
              setWasUpdated={setWasUpdated}
            />
          </div>
          <div className="row" style={{ marginTop: "-420px" }}>
            <div className="offset-6">
              <TitleContainer>
                <img src={IconFour} alt="" className="icon" />
                <p>Datos Adicionales</p>
              </TitleContainer>
            </div>
            <StyledContainer>
              <div className="offset-6 col-3">
                <ItemForm>
                  <SelectArquetipo
                    presetOptions={opciones.liftsWindows.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    value={formulario.liftsWindows}
                    useDatabaseOptions={false}
                    label="Levanta Cristales"
                    onChange={(option) =>
                      handleInputChange("liftsWindows", option)
                    }
                  />
                </ItemForm>
              </div>
              <div className="col-2">
                <ItemForm>
                  <SelectArquetipo
                    label="Ctrl. de velocidad"
                    presetOptions={opciones.speedControl.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    value={formulario.speedControl}
                    useDatabaseOptions={false}
                    onChange={(option) =>
                      handleInputChange("speedControl", option)
                    }
                  />
                </ItemForm>
              </div>
            </StyledContainer>
            <StyledContainer>
              <div className="offset-6 col-3">
                <ItemForm>
                  <SelectArquetipo
                    presetOptions={opciones.parkingSensor.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    label="Sensor de estacionamiento"
                    useDatabaseOptions={false}
                    value={formulario.parkingSensor}
                    onChange={(option) =>
                      handleInputChange("parkingSensor", option)
                    }
                  />
                </ItemForm>
              </div>
              <div className="col-2">
                <ItemForm>
                  <SelectArquetipo
                    label="CAM de retroceso"
                    presetOptions={opciones.backupCamera.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    useDatabaseOptions={false}
                    value={formulario.backupCamera}
                    onChange={(option) =>
                      handleInputChange("backupCamera", option)
                    }
                  />
                </ItemForm>
              </div>
            </StyledContainer>
            <StyledContainer>
              <div className="offset-6 col-1">
                <ItemForm>
                  <label className="label" htmlFor="airbags">
                    Airbags
                  </label>
                  <input
                    type="number"
                    label="Airbags"
                    value={formulario.airbag}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        airbag: e.target.value,
                      })
                    }
                  />
                </ItemForm>
              </div>
              <div className="col-1">
                <ItemForm>
                  <SelectArquetipo
                    presetOptions={opciones.abs.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    value={formulario.abs}
                    label="Abs"
                    useDatabaseOptions={false}
                    onChange={(option) => handleInputChange("abs", option)}
                  />
                </ItemForm>
              </div>
              <div className="col-1">
                <ItemForm>
                  <SelectArquetipo
                    label="Alarma"
                    value={formulario.alarm}
                    presetOptions={opciones.alarm.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    useDatabaseOptions={false}
                    onChange={(option) => handleInputChange("alarm", option)}
                  />
                </ItemForm>
              </div>
            </StyledContainer>
            <StyledContainer>
              <div className="offset-6 col-2">
                <ItemForm>
                  <SelectArquetipo
                    value={formulario.radio}
                    presetOptions={opciones.radio.map((opcion) => ({
                      label: opcion.label,
                      value: opcion.value,
                    }))}
                    label="Radio"
                    useDatabaseOptions={false}
                    onChange={(option) => handleInputChange("radio", option)}
                  />
                </ItemForm>
              </div>
              <div className="col-3">
                <ItemForm>
                  <label className="label" htmlFor="entertainment">
                    Entretenimiento
                  </label>
                  <input
                    type="text"
                    value={formulario.entertainment}
                    placeholder=" Entretenimiento..."
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        entertainment: e.target.value,
                      })
                    }
                  />
                </ItemForm>
              </div>
            </StyledContainer>
            <div className="container">
              <div className="row">
                <ButtonContainer className="offset-9 col-2">
                  <button
                    onClick={handleEditVehiculo}
                    className="primary-btn-big"
                  >
                    Confirmar
                  </button>
                </ButtonContainer>
              </div>
            </div>
          </div>
      </div>
      <div className="margin-bottom" style={{ height: "50px" }}></div>
    </MainContainer>
  );
};

export default EditarProductUsadoScreen;
