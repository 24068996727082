import React, { useEffect, useState } from "react";
import AdminProductRow from "../componentes/AdminUsadosRow";
import ModalAdminEliminar from "../componentes/ModalAdminEliminar";
import "../styles.css";
import axiosInstance from "../utils/ContextAxios";
import Spinner from "../componentes/Loader";
import ModalAdminVistaPreviaProduct from "../componentes/ModalAdminVistaPreviaProduct";
import ENDPOINTS from "../apiEndpoints";

const AdminProductsUsados = ({
  isModalDeleteOpen,
  openModalDelete,
  isModalViewOpen,
  openModalView,
  closeModalDelete,
  closeModalView,
  searchValueUsados,
}) => {
  const [selectedAuto, setSelectedAuto] = useState(null);
  const [update, setUpdate] = useState(true);
  //Estado del catalogo
  const [startIndex, setStartIndex] = useState(0);
  const cardsPerPage = 12;
  /*------------------------------------*/

  //producto
  const [autosLista, setAutosLista] = useState("");

  /*------api-------*/
  //pedir datos
  useEffect(() => {
    if(update){
      axiosInstance.get(ENDPOINTS.GET_USED_CARS)
      .then((res) => {
        console.log(res.data);
        setAutosLista(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      }).finally(() => setUpdate(false));
    }
  }, [update]);

  if (!autosLista) {
    return <Spinner />; // Muestra un mensaje mientras se carga la data
  }
  const filteredProductsUsados = autosLista.filter(
    (producto) =>
      producto.modelo.name
        .toLowerCase()
        .includes(searchValueUsados.toLowerCase()) ||
      producto.brand.name
        .toLowerCase()
        .includes(searchValueUsados.toLowerCase()) ||
      producto.domain.toLowerCase().includes(searchValueUsados.toLowerCase())
  );

  const visibleCards = filteredProductsUsados.slice(
    startIndex,
    startIndex + cardsPerPage
  );

  return (
    <div className="">
      {isModalViewOpen && (
        <ModalAdminVistaPreviaProduct
          closeModalView={closeModalView}
          producto={selectedAuto}
        />
      )}
      <div>
        {visibleCards.map((producto) => (
          <AdminProductRow
            key={producto._id}
            productoData={producto}
            openModalDelete={openModalDelete}
            isModalDeleteOpen={isModalDeleteOpen}
            isModalViewOpen={isModalViewOpen}
            closeModalView={closeModalView}
            closeModalDelete={closeModalDelete}
            openModalView={() => {
              setSelectedAuto(producto);
              openModalView();
            }}
            updateList={setUpdate}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminProductsUsados;
