import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../componentes/AdminNavbar";
import IconBack from "../images/icon_flecha_back.png";
import ModalAdminSalir from "../componentes/ModalAdminSalir";
import styled from "styled-components";
import SelectArquetipo, { SelectArquetipoMulti } from "../utils/SelectArq";
import "../styles.css";
import AgregarColor from "../componentes/ModalAdminColor";
import axiosInstance, { BASE_URL, token, useAxiosWithAuth } from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";
import UploadImages from "../componentes/UploadImages";
import Swal from "sweetalert2";
import IconDelete from "../images/icon_delete.png";

import AdminAlert from "../componentes/AdminResponsiveError";

const MainContainer = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  button {
    outline: none;
  }
  input {
    outline: none;
  }
  select {
    outline: none;
  }
  option:disabled {
    display: none;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: var(--font-family);
  margin-top: 10px;
  padding: 0;
`;

const DobleItemRow = styled.div`
  display: flex;
  height: 70px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -510px;
  .accesorios-input {
    height: 170px;
    margin-top: 15px;
    textarea {
      background-color: #f6f6f6;
      outline: none;
      border: none;
      height: 100%;
      padding-top: 5px;
    }
  }
  .garantia {
    margin-top: 45px;
  }
`;
const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
  font-family: var(--font-family);

  .label {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  input {
    height: 42px;
    width: 100%;
    border-radius: 6px;
    padding-left: 8px;
    border: none;
    background-color: var(--gris-invisible);
  }

  input:focus {
    border: 1px solid var(--prime-color);
  }

  .select-box {
    height: 42px;
  }
  ::placeholder {
    font-style: italic;
    color: black;
  }
`;

const FirstRow = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);
  margin-top: 42px;
  margin-bottom: 32px;

  h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 34px;
    margin: 0;
    span {
      color: var(--prime-color);
    }
  }

  .return-img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
  }

  .ficha-tecnica {
    font-weight: 700;
    text-decoration: underline;
    line-height: 20px;
    font-size: 16px;
    cursor: pointer;
  }
  img {
    position: absolute;
    left: 5%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: right;
  button {
    width: 162px;
  }
`;

const Editar0kmScreen = () => {
  const [producto, setProducto] = useState("");

  //CARGA DE DATOS DE CADA INPUT
  const [formulario, setFormulario] = useState({
    brand: "",
    modelo: "",
    version: "",
    category: "",
    motor: "",
    transmission: [],
    traction: "",
    fuel: "",
    seatMaterial: "",
    passengers: "",
    cv: "",
    colors: [],
    warranty: "",
    accessories: "",
    // ficha_tec: "",
    multimedia: [],
  });

  const fetchAndConvertToBlob = async (media) => {
    const response = await fetch(BASE_URL + '/' + media.path)
    if (!response.ok) throw new Error("Error fetching")
    return response.blob().then(blob => {
        return { 
          blob:blob, 
          color:media.color,
          name: media.filename,
          mediaId:media._id,
          favorite:media.favorite  
        }
      })
  }

  const mapFormulario = (data) => {
    setFormulario({
      brand: data.brand
        ? { label: data.brand.name, value: data.brand._id }
        : "",
      modelo: data.modelo
        ? { label: data.modelo.name, value: data.modelo._id }
        : "",
      version: data.version
        ? { label: data.version.name, value: data.version._id }
        : "",
      category: data.category
        ? { label: data.category.name, value: data.category._id }
        : "",
      motor: data.motor ? { label: data.motor, value: data.motor } : "",
      transmission: data.transmission.map((option) => ({
        label: option,
        value: option,
      })),
      traction: data.traction
        ? { label: data.traction, value: data.traccion }
        : "",
      fuel: data.fuel ? { label: data.fuel, value: data.fuel } : "",
      seatMaterial: data.seatMaterial
        ? {
          label: data.seatMaterial,
          value: data.seatMaterial,
        }
        : "",
      passengers: data.passengers || "",
      cv: data.cv || "",
      colors: data.colors.map((option) => ({
        label: option.name,
        code: option.code,
        value: option._id,
        key: option._id,
      })),
      warranty: data.warranty || "",
      accessories: data.accessories || "",
      // ficha_tec: data.ficha_tec || "",
      multimedia: data.multimedia || "",
    });
  }

  //pedir datos
  useEffect(() => {
    const localId = localStorage.getItem("ProductoId");
    const autoId = JSON.parse(localId);


    try {
      axiosInstance.get(
        ENDPOINTS.GET_NEW_CAR_BY_ID(autoId)
      ).then(async ({ data }) => {
        const { data: car } = data;
        setProducto(car);
        console.log("Response", car)

        const imageTypes = ["image/jpeg", "image/png", "image/gif", "text/plain"];
        const imageFiles = car.multimedia.filter((media) =>
          imageTypes.includes(media.mimetype)
        );

        const pdf = car.multimedia.filter((media) =>
          media.mimetype === "application/pdf"
        );
        if(pdf.length > 0) {
          setPdf([{
            name: pdf[0].filename,
            updated:false,
            id:pdf[0]._id
          }])
        }
        mapFormulario(car);

        return Promise.all(imageFiles.map(file => {
          if(file.filetype === 'video') {
            return {
                name: file.path,
                mediaId:file._id,
                favorite:file.favorite,
                url:file.path  
              }
          } else {
            return fetchAndConvertToBlob(file)
          }
          
        }
        ))

      }).then(res => {
        const files = res.map(data => {
          let file;
          if(data.blob ){
            const { blob } = data
            file = new File([blob], data.name, { type: blob.type })
            file.color = data.color
            file.id = data.mediaId
            file.favorite = data.favorite
            file.updated = false
          }else{
            file = new File([data.url], "video.txt", {
              type: "text/plain",
            });
            file['url'] = data.url;
            file.updated = false;
            file.id = data.mediaId;
            file.favorite = data.favorite
          }
          return file
        })
        setFilesUpload(files)
      })
      .finally((res) => {
        console.log("Finish");
      })

    } catch (error) {
      console.log("Error:", error);
    }

  }, []);
  //console.log(formulario);
  //console.log(formulario);
  /********************************************* */

  //actualizar los inputs cuando se crea una nueva opcion
  const [reloadKey, setReloadKey] = useState(Date.now());

  const reloadOptions = () => {
    setReloadKey(Date.now());
  };

  /********************/
  const axiosInstanceWithAuth = useAxiosWithAuth();

  const [filesUpload, setFilesUpload] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  //abrir modal salir
  const [isModalSalirOpen, setModalSalirOpen] = useState();
  //abrir modal color
  const [isModalColorOpen, setModalColorOpen] = useState(false);
  //checkea si se actualizo un dato o no
  const [wasUpdated, setWasUpdated] = useState(false);

  const navigate = useNavigate();

  /******************** */

  // Opciones practicas para cada select
  const opciones = {
    category: ["Auto", "SUV", "Camioneta", "Pick-Up", "Utilitario"],
    motor: [
      "1.0",
      "1.2",
      "1.4",
      "1.5",
      "1.6",
      "1.8",
      "2.0",
      "2.2",
      "2.3",
      "2.5",
      "2.8",
      "3.0",
      "3.2",
      "3.4",
      "3.5",
      "4.0",
      "5.0",
      "5.7",
      "6.0",
    ],
    transmission: ["Automática", "Manual", "Hidráulica", "EVT", "CVT", "DSG"],
    seatMaterial: ["Tela", "Cuero", "Vinilo", "Ante", "Microfibra"],
    traction: ["Delantera", "Trasera", "Total", "4x4"],
    fuel: ["GNC", "Diésel", "Nafta", "Gasoil", "Eléctrico", "Biocombustible"],
  };

  const handleInputChange = (field, value) => {
    setFormulario({ ...formulario, [field]: value });
  };

  const handleAddPDF = (e) => {
    const files = Array.from(e.target.files);
    setPdf(files);
  }

  //boton regresar
  const handleReturnButton = () => {
    setModalSalirOpen(true);
  };
  //cerrar boton regresar
  const handleReturnButtonClose = () => {
    setModalSalirOpen(false);
  };

  //manejador de Color
  const handleOpenColor = () => {
    setModalColorOpen(true);
  };

  const closeModalColor = () => {
    setModalColorOpen(false);
  };

  //Crear tipos:
  const handleCreateCategory = async (inputValue) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_CATEGORY,
        {
          name: inputValue,
          isMain: inputValue.isMain || false,
        },
        {
          headers: `Bearer ${token}`,
        }
      );
      const newTipo = {
        label: response.data.data.nombre,
        name: inputValue.label,
        isMain: inputValue.isMain || false,
      };
      setSelectedCategory(newTipo);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new marca:", error);
    }
  };

  //Crear tipos:
  const handleCreateColor = async (colorData) => {
    try {
      const response = await axiosInstanceWithAuth.post(
        ENDPOINTS.CREATE_COLOR,
        {
          name: colorData.name,
          code: colorData.code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const newColor = {
        label: response.data.data.name,
        name: response.data.data.name,
        _id: response.data.data._id,
        code: response.data.data.code,
      };
      console.log(response);
      setSelectedColor(newColor);
      reloadOptions();
    } catch (error) {
      console.error("Error creating new color:", error);
    }
  };

  //llamado para crear auto
  const handleEditVehiculo = async () => {
    const id = producto._id;
    try {
      const response = await axiosInstanceWithAuth
        .put(`${ENDPOINTS.UPDATE_NEW_CAR}/${id}`, {
          newCar: 1,
          brand: formulario.brand.value,
          modelo: formulario.modelo._id,
          version: formulario.version._id,
          warranty: formulario.warranty,
          passengers: formulario.passengers,
          accessories: formulario.accessories,
          motor: formulario.motor.label,
          cv: formulario.cv.toString(),
          traction: formulario.traction.label,
          fuel: formulario.fuel.label,
          transmission: formulario.transmission.label,
          seatMaterial: formulario.seatMaterial.label,
          // ficha_tec: formulario.ficha_tec,
          colors: formulario.colors.map((color) => color.value),
          category: formulario.category.value,
          //  multimedia: formulario.multimedia,
        })
        .then(async ({ data }) => {
          const newCarData = data.data;

          const formData = new FormData();
          
          const filesToUpdate = filesUpload.filter(file => file.id);
          const filesToCreate = filesUpload.filter(file => !file.id);

          // si filesToUpdate un endpoint  que solo actualice los datos
          if (wasUpdated) {
            await axiosInstanceWithAuth
              .put(ENDPOINTS.UPDATE_MULTIMEDIA(newCarData._id), {
                data: filesToUpdate
              }).then((res) => console.log(res))
          }
          let addFile = false
          if(pdf.length > 0){
            formData.append("files", pdf[0])
            addFile = true
          }
          // si filesToCreate endpoint que crea y agrega las imagenes
          if (filesToCreate.length > 0) {
            let filesData=[]
            filesToCreate.forEach((file, index) => {
              formData.append("files", file);
              if(file.type === "text/plain"){
                filesData.push({
                  url:file.url
                })
              }else {
                filesData.push({
                favorite: file.favorite,
                colorId: file.color,
              })
              }
              
            });
            formData.append('data',JSON.stringify(filesData))
            addFile = true
            console.log(formData)
          }
          if(addFile) {
            console.log("agregar")
            await axiosInstanceWithAuth
                .post(ENDPOINTS.UPLOAD_MULTIMEDIA(newCarData._id), formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((res) => console.log('uploadsuccess',res))
                .catch((err) => console.log(err));
          }
        }).then((e) => Swal.fire({
          icon: 'success',
          title: 'Datos actualizados correctamente',

          iconColor: '#1A3EEA',
          showConfirmButton: false,
          timer: 2000,
        }).then(() => navigate("/admin/home")))
        .catch((err) => Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          iconColor: '#dc3545',
          showConfirmButton: false,
          timer: 2000,
        }));

      //navigate("/admin/home");
    } catch (error) {
      console.error("Error creating new vehiculo:", error);
    }
  };

  return (
    <MainContainer className="container-fluid">
      <Navbar />
      {isModalSalirOpen && (
        <ModalAdminSalir
          handleReturnButtonClose={handleReturnButtonClose}
          closeModal={handleReturnButtonClose}
        />
      )}
      {isModalColorOpen && (
        <AgregarColor
          closeModal={closeModalColor}
          onColorAdded={handleCreateColor}
        />
      )}
      <div className="container">
        <div className="row">
          <FirstRow>
            <img
              src={IconBack}
              alt="return-img"
              className="return-img"
              onClick={handleReturnButton}
            />
            <div className=" offset-1 col-8">
              <div className="title">
                <h1>
                  <span>
                    {formulario.brand.label} {formulario.modelo.label}{" "}
                    {formulario.version.label}
                  </span>
                </h1>
              </div>
            </div>
              <div className="ficha-tecnica">
                <label htmlFor="add-file" className="btn-pdf">
                  {pdf.length > 0 ?
                    <div style={{display:"flex", alignItems:"center"}}>
                      <span>{pdf[0].name}</span>
                      <button style={{ position:"relative", border:"none", background:"none", display:"flex", justifyContent:"center", alignItems:"center"}}  type="button" onClick={() => setPdf([])}>
                        <img src={IconDelete} alt="Eliminar" style={{position:"relative"}}  />
                      </button></div>
                  :<>
                  + <span>Ficha técnica (pdf)</span>
                  
                  <input
                    type="file"
                    id="add-file"
                    multiple
                    style={{ display: "none"}}
                    onChange={handleAddPDF}
                    accept=".pdf"
                  /></>}
                </label>
              </div>
          </FirstRow>
        </div>
        <div className="row">
          <StyledContainer>
            <div className="offset-1 col-3">
              <ItemBox>
                <div className="select-box">
                  <SelectArquetipo
                    label="Categoria"
                    endpoint="/api/v1/car/attributes"
                    useDatabaseOptions={true}
                    onCreateOption={handleCreateCategory}
                    value={formulario.category}
                    onChange={(selectedOption) =>
                      setFormulario({
                        ...formulario,
                        category: selectedOption,
                      })
                    }
                  />
                </div>
              </ItemBox>
            </div>
            <div className=" col-3">
              <ItemBox>
                <SelectArquetipo
                  label="Traccion"
                  useDatabaseOptions={false}
                  value={formulario.traction}
                  presetOptions={opciones.traction.map((opcion) => ({
                    label: opcion,
                    value: opcion,
                  }))}
                  onChange={(selectedOption) =>
                    setFormulario({
                      ...formulario,
                      traction: selectedOption,
                    })
                  }
                />
              </ItemBox>
            </div>
            <div className="col-4">
              <ItemBox>
                <SelectArquetipoMulti
                  label="Transmision"
                  isMulti={true}
                  value={formulario.transmission}
                  useDatabaseOptions={false}
                  presetOptions={opciones.transmission.map((opcion) => ({
                    value: opcion,
                    label: opcion,
                  }))}
                  onChange={(selectedOptions) =>
                    setFormulario({
                      ...formulario,
                      transmission: selectedOptions,
                    })
                  }
                />
              </ItemBox>
            </div>
          </StyledContainer>
        </div>
        <div className="row">
          <StyledContainer>
            <div className="offset-1 col-3">
              <ItemBox>
                <SelectArquetipo
                  label="Motor"
                  value={formulario.motor}
                  presetOptions={opciones.motor.map((opcion) => ({
                    label: opcion,
                    value: opcion,
                  }))}
                  useDatabaseOptions={false}
                  onChange={(option) => handleInputChange("motor", option)}
                />
              </ItemBox>
            </div>
            <div className=" col-3">
              <ItemBox>
                <label htmlFor="potencia" className="label">
                  Potencia
                </label>
                <input
                  type="number"
                  name="potencia"
                  value={formulario.cv}
                  onChange={(e) =>
                    setFormulario({
                      ...formulario,
                      cv: parseInt(e.target.value),
                    })
                  }
                />
              </ItemBox>
            </div>
            <div className=" col-4">
              <ItemBox>
                <SelectArquetipo
                  label="Combustible"
                  useDatabaseOptions={false}
                  presetOptions={opciones.fuel.map((opcion) => ({
                    label: opcion,
                    value: opcion,
                  }))}
                  value={formulario.fuel}
                  onChange={(selectedOption) =>
                    setFormulario({
                      ...formulario,
                      fuel: selectedOption,
                    })
                  }
                />
              </ItemBox>
            </div>
          </StyledContainer>
        </div>
        <div className="margin-container" style={{ height: "40px" }}></div>

        <div className="row">
          <StyledContainer>
            <div className="offset-1 col-5">
              <ItemBox>
                <SelectArquetipoMulti
                  label="Color"
                  endpoint="/api/v1/car/color"
                  isMulti={true}
                  value={formulario.colors.map((color) => {
                    console.log(color)
                    console.log(formulario.colors)
                    return {
                      label: color.label,
                      value: color.value,
                      code: color.code,
                      key: color.value
                    }
                  })}
                  useDatabaseOptions={true}
                  onChange={(selectedOption) => {
                    const mapColor = selectedOption.map((color) => {
                      console.log(color)
                      return {
                        label: color.label,
                        value: color.value,
                        code: color.code,
                        key: color.value
                      };
                    });
                    setFormulario({
                      ...formulario,
                      colors: mapColor,
                    });
                  }}
                  onCreateOption={handleOpenColor}
                />
              </ItemBox>
            </div>
            <UploadImages
              className={'offset-1 col-4'}
              filesUpload={filesUpload}
              setFilesUpload={setFilesUpload}
              colors={formulario.colors}
              setWasUpdated={setWasUpdated}
              warningColor={true}
            >
              <ButtonContainer>
                <button
                  onClick={handleEditVehiculo}
                  className="primary-btn-big"
                >
                  Confirmar
                </button>
              </ButtonContainer>
            </UploadImages>
          </StyledContainer>
          <Container>
            <DobleItemRow>
              <div className="offset-1 col-2">
                <ItemBox>
                  <label className="label">Pasajeros</label>
                  <input
                    type="number"
                    name="Pasajeros"
                    value={formulario.passengers}
                    onChange={(e) =>
                      setFormulario({
                        ...formulario,
                        passengers: parseInt(e.target.value),
                      })
                    }
                  />
                </ItemBox>
              </div>
              <div className="col-3">
                <ItemBox>
                  <SelectArquetipo
                    label="Material de Asientos"
                    presetOptions={opciones.seatMaterial.map((opcion) => ({
                      label: opcion,
                      value: opcion,
                    }))}
                    useDatabaseOptions={false}
                    value={formulario.seatMaterial}
                    onChange={(selectedOption) =>
                      setFormulario({
                        ...formulario,
                        seatMaterial: selectedOption,
                      })
                    }
                  />
                </ItemBox>
              </div>
            </DobleItemRow>
            <div className="offset-1 col-5">
              <ItemBox className="garantia">
                <label className="label">Garantía</label>
                <input
                  type="text"
                  name="garantia"
                  value={formulario.warranty}
                  onChange={(e) =>
                    handleInputChange("warranty", e.target.value)
                  }
                  placeholder="Garantía"
                />
              </ItemBox>
            </div>
            <div className="offset-1 col-5">
              <ItemBox className="accesorios-input">
                <label className="label">Accesorios</label>
                <textarea
                  name="accesorios"
                  value={formulario.accessories}
                  onChange={(e) =>
                    handleInputChange("accessories", e.target.value)
                  }
                  placeholder="| Escribe información sobre accesorios complementarios"
                />
              </ItemBox>
            </div>
          </Container>
        </div>
      </div>
    </MainContainer>
  );
};

export default Editar0kmScreen;
