import Car0km from "./images/0kmCar.png";
import CarUsados from "./images/CarUsados.png";

const cardData = [
  {
    id: 1,
    imageSrc: Car0km,
    name: "Toyota Corolla",
    ruta: "usado",
    version: "Sport",
    motor: "1.8L",
    puertas: "4 Puertas",
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Toyota",
    categoria: "Auto",
    tipo: "Sedán",
    año: 2020,
    color: "Azul",
    precio: "$22.500.000",
    kilometros: 40000,
    patente: "ABC 123",
    garantia: "2 años o 50.000 km",
    accesorios:
      "Tuercas de seguridad, estribo lateral, moldura lateral, parrilla de techo portaequipaje.",
    traccion: "Delantera",
    combustible: "Gasolina",
    potencia: "140",
    dominio: "BBB12345",
    asientos: "5",
    asientos_interior: "Tela",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 2,
    imageSrc: Car0km,
    name: "Honda Civic",
    version: "Sport",
    ruta: "usado",
    motor: "2.0L",
    puertas: "4 Puertas",
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Honda",
    categoria: "Auto",
    tipo: "Sedán",
    año: 2019,
    color: "Gris",
    precio: "$20.800.000",
    kilometros: 45000,
    patente: "DEF 789",
    garantia: "1 año o 30.000 km",
    accesorios: "Navegación GPS. Asientos de cuero",
    traccion: "Delantera",
    combustible: "Gasolina",
    potencia: "160",
    dominio: "LKDH4545",
    asientos: "5",
    asientos_interior: "Cuero",
    destacado: true,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 3,
    imageSrc: CarUsados,
    version: "Longitud",
    name: "Ford Mustang",
    ruta: "usados",
    motor: "5.0L",
    puertas: "2 Puertas",
    capacidad: "2 personas",
    transmision: "Manual",
    marca: "Ford",
    categoria: "Auto",
    tipo: "Deportivo",
    año: 2021,
    color: "Rojo",
    precio: "$35.000.000",
    kilometros: 20000,
    patente: "XYZ 456",
    garantia: "3 años o 80.000 km",
    accesorios: "Sistema de escape deportivo. Techo corredizo",
    traccion: "Trasera",
    combustible: "Gasolina",
    potencia: "450",
    dominio: "DDD8964",
    asientos: "2",
    asientos_interior: "Cuero",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 4,
    imageSrc: Car0km,
    name: "Toyota Corolla",
    ruta: "nuevos",
    motor: "1.8L",
    version: "Longitud",
    puertas: "4 Puertas",
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Toyota",
    categoria: "Auto",
    tipo: "Sedán",
    año: 2020,
    color: "Azul",
    precio: "$22.500.000",
    kilometros: 40000,
    patente: "ABC 123",
    garantia: "2 años o 50.000 km",
    accesorios: "Cámara de retroceso. Sensores de estacionamiento",
    traccion: "Delantera",
    combustible: "Gasolina",
    potencia: "140",
    dominio: "NXF12Y5",
    asientos: "5",
    asientos_interior: "Tela",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 5,
    imageSrc: Car0km,
    name: "Honda Civic",
    ruta: "nuevos",
    motor: "2.0L",
    puertas: "4 Puertas",
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Honda",
    categoria: "Auto",
    tipo: "Sedán",
    año: 2019,
    color: "Gris",
    precio: "$20.800.000",
    kilometros: 45000,
    patente: "DEF 789",
    garantia: "1 año o 30.000 km",
    accesorios: "Navegación GPS. Asientos de cuero",
    traccion: "Delantera",
    combustible: "Gasolina",
    potencia: "160",
    dominio: "CBWE7895",
    asientos: "5",
    asientos_interior: "Cuero",
    destacado: true,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 6,
    imageSrc: CarUsados,
    name: "Ford Mustang",
    ruta: "usados",
    motor: "5.0L",
    puertas: "2 Puertas",
    capacidad: "2 personas",
    transmision: "Manual",
    marca: "Ford",
    categoria: "Auto",
    tipo: "Deportivo",
    año: 2021,
    color: "Rojo",
    precio: "$35.000.000",
    kilometros: 20000,
    patente: "XYZ 456",
    garantia: "3 años o 80.000 km",
    accesorios: "Sistema de escape deportivo. Techo corredizo",
    traccion: "Trasera",
    combustible: "Gasolina",
    potencia: "450",
    dominio: "DDÑPF131",
    asientos: "2",
    asientos_interior: "Cuero",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 7,
    imageSrc: CarUsados,
    name: "Hyundai Tucson",
    ruta: "usados",
    description: "Descripción del producto 8",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Hyundai",
    categoria: "0km",
    tipo: "suv",
    año: 2022,
    color: "Rojo",
    precio: "$26,000",
    kilometros: 30000,
    patente: "XCA 450",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 8,
    imageSrc: Car0km,
    name: "Mazda 3",
    description: "Descripción del producto 9",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Mazda",
    categoria: "0km",
    ruta: "nuevos",
    tipo: "auto",
    año: 2023,
    color: "Negro",
    precio: "$23,000",
    kilometros: 25000,
    patente: "XYY 108",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 9,
    imageSrc: "url-de-la-imagen-10.jpg",
    name: "Subaru Outback",
    description: "Descripción del producto 10",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Subaru",
    categoria: "usado",
    tipo: "suv",
    año: 2018,
    color: "Verde Oscuro",
    precio: "$20,000",
    kilometros: 60000,
    patente: "BNK 266",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
    ruta: "usados",
  },
  {
    id: 10,
    imageSrc: Car0km,
    name: "Mazda 3",
    description: "Descripción del producto 9",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Mazda",
    categoria: "0km",
    ruta: "nuevos",
    tipo: "auto",
    año: 2023,
    color: "Negro",
    precio: "$23,000",
    kilometros: 25000,
    patente: "XYY 108",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 11,
    imageSrc: "url-de-la-imagen-10.jpg",
    name: "Subaru Outback",
    description: "Descripción del producto 10",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Subaru",
    categoria: "usado",
    tipo: "suv",
    año: 2018,
    color: "Verde Oscuro",
    precio: "$20,000",
    kilometros: 60000,
    patente: "BNK 266",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
    ruta: "usados",
  },
  {
    id: 12,
    imageSrc: Car0km,
    name: "Mazda 3",
    description: "Descripción del producto 9",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Mazda",
    categoria: "0km",
    ruta: "nuevos",
    tipo: "auto",
    año: 2023,
    color: "Negro",
    precio: "$23,000",
    kilometros: 25000,
    patente: "XYY 108",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 13,
    imageSrc: "url-de-la-imagen-10.jpg",
    name: "Subaru Outback",
    description: "Descripción del producto 10",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Subaru",
    categoria: "usado",
    tipo: "suv",
    año: 2018,
    color: "Verde Oscuro",
    precio: "$20,000",
    kilometros: 60000,
    patente: "BNK 266",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
    ruta: "usados",
  },
  {
    id: 14,
    imageSrc: Car0km,
    name: "Mazda 3",
    description: "Descripción del producto 9",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Mazda",
    categoria: "0km",
    ruta: "nuevos",
    tipo: "auto",
    año: 2023,
    color: "Negro",
    precio: "$23,000",
    kilometros: 25000,
    patente: "XYY 108",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
  },
  {
    id: 15,
    imageSrc: "url-de-la-imagen-10.jpg",
    name: "Subaru Outback",
    description: "Descripción del producto 10",
    motor: "Motor Convencional",
    puertas: 4,
    capacidad: "5 personas",
    transmision: "Automática",
    marca: "Subaru",
    categoria: "usado",
    tipo: "suv",
    año: 2018,
    color: "Verde Oscuro",
    precio: "$20,000",
    kilometros: 60000,
    patente: "BNK 266",
    garantia: "3 años o 100.000 km",
    accesorios: "Tuercas de seguridad. Seguros",
    traccion: "Delantera",
    combustible: "Nafta",
    potencia: "115",
    destacado: false,
    airbags: "si",
    GNC: "no",
    ruta: "usados",
  },
];

export default cardData;
