import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import IconMotor from "../images/icon_motor.png";
import IconAsientos from "../images/icon_asientos.png";
import IconCombustible from "../images/icon_combustible.png";
import IconInterior from "../images/icon_interior.png";
import IconGarantia from "../images/icon_garantia.png";
import IconTraccion from "../images/icon_traccion.png";
import IconSetting from "../images/Setting.png";
import IconCar from "../images/icon_car.png";
import BannerImg from "../images/BannerProducts.png";
import ModalCotizador from "../componentes/ModalCotizador";
import ModalMeInteresa from "../componentes/ModalMeInteresa";
import ModalFinanciacion from "../componentes/ModalFinanciacion1";
import AsideProduct0km from "../componentes/AsideProduct0km";
import "../styles.css";
import AsideProduct0kmMobile from "../componentes/AsideProduct0kMobile";
import { SliderCar } from "../componentes/CarrouselCarModelo";
import SliderCarMobile from "../componentes/CarrouselCarModeloMobile";
import { Link, useParams } from "react-router-dom";
import Spinner from "../componentes/Loader";
import WhatsAppBtn from "../componentes/WhatsAppBtn";
import axiosInstance from "../utils/ContextAxios";
import ENDPOINTS from "../apiEndpoints";

const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;
const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;

  button {
    outline: none;
  }
  /* p {
    margin: 0;
  } */
  .banner {
    height: 149px;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: auto;
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
  .title-mobile {
    display: none;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* margin-bottom: 15px; */
      p {
        margin-bottom: 4px;
        font-size: 18px;
        line-height: 18px;
      }
      .car-name {
        font-size: 21px;
        line-height: 21px;
        font-weight: 700;
      }
    }
  }
  .ficha-row {
    display: flex;
    justify-content: center;
  }
`;

const BreadcrumbContainer = styled.div`
  width: 100%;
  align-items: center;
  background-color: #eaedf4;
  display: flex;
  height: 32px;
  @media (max-width: 576px) {
    display: none;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  align-items: center;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;

  a {
    list-style: none;
    text-decoration: none;
    font-weight: 400;
    color: #595e62;
  }
`;

const ItemsCarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  margin-top: 45px;
  width: 100%;
  height: 187px;
  padding: 10px 0px;
  border-radius: 6px;

  .items-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .garantia {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 30px;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  .card-motor {
    border: transparent;
    border-right: 1px solid var(--gris-medio);
    img {
      margin-top: 4px;
    }
  }
  .card-asientos {
    border: transparent;
    border-right: 1px solid var(--gris-medio);
  }
  .card-combustible {
    border: transparent;
  }
  .card-interior {
    border: transparent;
    border-left: 1px solid var(--gris-medio);
  }

  .icon-img {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;
const ItemsCarContainerMobile = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 30px;
    height: 138px;
    background-color: #f6f6f6;
    padding: 8px;
    border-radius: 6px;
    .side-1 {
      width: 50%;
      height: 100%;
      border-right: 1px solid var(--gris-medio);
    }
    .side-2 {
      width: 50%;
      height: 100%;
    }
    .garantia {
      display: flex;
      justify-content: center;
      text-align: center;
      height: 15px;
      font-family: var(--font-family);
      font-size: 13px;
      font-weight: 400;
      line-height: 13px;
      margin-top: 20px;

      .icon-img {
        max-width: 16px;
        max-height: 16px;
        margin-right: 6px;
      }
    }
  }
`;

const ItemCar = styled.div`
  display: flex;
  flex-direction: column;
  height: 108px;
  width: 100%;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 18px;
  font-size: 16px;
  background-color: #f6f6f6;
  text-align: center;
  align-items: center;
  padding: 0;

  .img-container {
    padding-top: 8px;
    justify-content: center;
    height: 64px;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    /* padding: 6px; */
    /* height: 40%; */
  }

  span {
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;
const ItemCarMobile = styled.div`
  display: none;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: row;
    height: 42px;
    font-weight: 700;
    line-height: 14px;
    font-size: 14px;
    padding: 10px;
    align-items: center;

    .info-box {
      display: flex;
      flex-direction: column;

      height: 100%;
      text-align: left;
      font-family: var(--roboto);
      width: 100%;
      span {
        font-size: 13px;
      }
    }
    .img-container {
      width: auto;
      height: 100%;
      margin: 0px 6px;
    }
  }
`;

const FichaTecnica = styled.button`
  display: flex;
  /* text-align: center; */
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  font-family: var(--font-family);
  text-decoration: underline;
  width: 150px;
  background: transparent;
  border: none;
  margin-top: 25px;
  margin-bottom: 70px;
  cursor: pointer;
  color: black;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover {
    color: var(--prime-color);
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

const CaracteristicasContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
  width: 100%;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;

  .title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 38px;
  }

  .icons {
    display: flex;
    width: 420px;
  }
  .setting {
    display: flex;
  }
  .icon_setting {
    margin-right: 5px;
    margin-left: -5px;
  }

  .icon-div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0px;
  }
  .icon-div p {
    margin-bottom: -5px;
  }

  img {
    margin-right: 8px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    margin-top: 0;
    height: 220px;
    margin-left: 10%;
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      margin: 0;
    }
    img {
      height: 20px;
      width: auto;
    }
    .icons {
      display: block;
      width: 100%;
    }
    .icon-div {
      margin: 15px 0px;
    }
    .setting {
      margin-left: 4px;
    }
  }
`;

const Producto0km = () => {
  //Estado de los modales
  const [producto, setProducto] = useState(null);
  const [formData, setFormData] = useState(null);
  const [relatedColor, setRelatedColor] = useState();
  const [versionIdChange, setVersionIdChange] = useState("");
  const [transmissionIdChange, setTransmissionIdChange] = useState("");
  const [versionsBack, setVersionsBack] = useState([]);
  const [modalAsesorVisible, setModalAsesorVisible] = useState(false);
  const [modalMeInteresa, setModalMeInteresa] = useState(false);
  const [modalCotizadorVisible, setModalCotizadorVisible] = useState(false);
  const [modalFinanciacionVisible, setModalFinanciacionVisible] =
    useState(false);

  const {productId} = useParams();
  /******************************************************* */
  const fetchData = async () => {
    
    try {
      const response = await axiosInstance.get(
        ENDPOINTS.GET_NEW_CAR_BY_ID(productId)
      )/* .then(response => {
        //console.log("response", response);
      }) */;
      setProducto(response.data.data);
      setVersionIdChange(response.data.data.version._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(producto);

  /******************************************************* */

  const fetchVersionsBack = async () => {
    try {
      const versionResponse = await axiosInstance(`/api/v1/car/attributes`);

      const dataBack = await versionResponse.data.data.brands;

      const versionesData = dataBack.flatMap((brand) =>
        brand.modelos
          .filter((modelo) => modelo._id === producto.modelo._id)
          .flatMap((modelo) =>
            modelo.versions.map((version) => ({
              id: version._id,
              name: version.name,
            }))
          )
      );

      setVersionsBack(versionesData);
    } catch (error) {
      console.error("Error fetching versiones:", error);
    }
  };

  const fetchProductoByVersion = async (versionId) => {
    try {
        await axiosInstance(ENDPOINTS.GET_NEW_CAR_BY_VERSION_ID(versionId))
      .then(res => {
        const { data } = res.data;
        console.log(data);
        setProducto(data);
      })



      /* const dataBack = await versionResponse.data.data;
      console.log(dataBack, "Data VERSIONES");
      const newProducto = dataBack.find((car) => car.version._id === versionId);

      if (newProducto) {
        setProducto(newProducto);
      }

      console.log(newProducto); */
    } catch (error) {
      console.error("Error fetching producto:", error);
    }
  };
  /******************************************************************/

  useEffect(() => {
    fetchVersionsBack();
  }, [producto]);

  /***********************************************************/
  if (!producto) {
    return <Spinner />; // Muestra un mensaje mientras se carga la data
  }

  const closeModalCotizador = () => {
    setModalCotizadorVisible(false);
  };
  const closeModalMeInteresa = () => {
    setModalMeInteresa(false);
  };

  const closeModalFinanciacion = () => {
    setModalFinanciacionVisible(false);
  };

  const handleAsesorClick = () => {
    setModalAsesorVisible(true);
  };
  const handleMeInteresaClick = () => {
    setModalMeInteresa(true);
  };
  const handleFinanciacionClick = () => {
    setModalFinanciacionVisible(true);
  };

  const handleCotizadorClick = () => {
    setModalCotizadorVisible(true);
  };

  const handleCotizadorFormData = (formData) => {
    //setFormData(formData);

    console.log(formData);
  };

  const productoVersionId = (value) => {
    setVersionIdChange(value);
    fetchProductoByVersion(value);
  };

  /******Obtener las versiones de acuerdo a la Transmission */
  const productoTransmissionId = (value) => {
    setTransmissionIdChange(value);
    // fetchProductoByVersion(value);
  };

  return (
    <Container className="container-fluid">
      <Navbar />
      <MarginNav />
      <BreadcrumbContainer>
        <div className="container">
          <div className="row">
            <div className="col-5">
              <Breadcrumb>
                <p>
                  <Link to={"/nuevos"}>Vehículos Nuevos</Link>
                  <span style={{ color: "#595e62" }}>{" > "}</span>
                  {producto.brand.name} {producto.modelo.name}
                </p>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </BreadcrumbContainer>
      <div className="container">
        {modalCotizadorVisible && (
          <ModalCotizador
            closeModal={closeModalCotizador}
            onSubmit={handleCotizadorFormData}
          />
        )}
        {modalFinanciacionVisible && (
          <ModalFinanciacion closeModal={closeModalFinanciacion} />
        )}
        {modalMeInteresa && (
          <ModalMeInteresa closeModal={closeModalMeInteresa} car={producto} />
        )}
        <div className="row">
          <SliderCarMobile
            productoImg={producto.multimedia}
            relatedColor={relatedColor}
            producto={producto}
          />
          <div className="col-md-7">
            <SliderCar
              productoImg={producto.multimedia}
              relatedColor={relatedColor}
              producto={producto}
            />
            <div className="row">
              <div className="title-mobile offset-3 col-6">
                <p className="car-name">
                  {producto.brand.name} {producto.modelo.name}
                </p>
                <p>{producto.version.name}</p>
              </div>
            </div>

            <div>
              <ItemsCarContainer>
                <div className="items-container">
                  <ItemCar className=" card-motor">
                    <div className="img-container">
                      <img
                        src={IconMotor}
                        alt="IconMotor"
                        className="card-img img-motor"
                      />
                    </div>
                    <div className="info-box">
                      <p>
                        <span>Motor & Potencia</span>
                        <br />
                        {producto.motor}
                      </p>
                    </div>
                  </ItemCar>
                  <ItemCar className="card-asientos">
                    <div className="img-container">
                      <img
                        src={IconAsientos}
                        alt="iconAsientos"
                        className="card-img"
                      />
                    </div>
                    <div className="info-box">
                      <p>
                        <span>Asientos</span>
                        <br />
                        {producto.passengers}
                      </p>
                    </div>
                  </ItemCar>
                  <ItemCar className="card-combustible">
                    <div className="img-container">
                      <img
                        src={IconCombustible}
                        alt="Producto"
                        className="card-img"
                      />
                    </div>
                    <div className="info-box">
                      <p>
                        <span>Combustible</span>
                        <br />
                        {producto.fuel}
                      </p>
                    </div>
                  </ItemCar>
                  <ItemCar className="card-interior">
                    <div className="img-container">
                      <img
                        src={IconInterior}
                        alt="Producto"
                        className="card-img"
                      />
                    </div>
                    <div className="info-box">
                      <p>
                        <span>Interior</span>
                        <br />
                        {producto.seatMaterial}
                      </p>
                    </div>
                  </ItemCar>
                </div>
                <div className="garantia">
                  <img
                    src={IconGarantia}
                    alt="icon_garantia"
                    className="icon-img"
                  />
                  {`Garantía: ${producto.warranty}`}
                </div>
              </ItemsCarContainer>
              <ItemsCarContainerMobile>
                <div className="d-flex">
                  <div className="side-1">
                    <ItemCarMobile className="card-motor">
                      <div className="img-container">
                        <img
                          src={IconMotor}
                          alt="IconMotor"
                          className="card-img img-motor"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Motor & Potencia</span>
                          <br />
                          {producto.motor}
                        </p>
                      </div>
                    </ItemCarMobile>
                    <ItemCarMobile className="card-combustible">
                      <div className="img-container">
                        <img
                          src={IconCombustible}
                          alt="Producto"
                          className="card-img"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Combustible</span>
                          <br />
                          {producto.fuel}
                        </p>
                      </div>
                    </ItemCarMobile>
                  </div>
                  <div className="side-2">
                    <ItemCarMobile className="card-asientos">
                      <div className="img-container">
                        <img
                          src={IconAsientos}
                          alt="iconAsientos"
                          className="card-img"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Asientos</span>
                          <br />
                          {producto.passengers}
                        </p>
                      </div>
                    </ItemCarMobile>
                    <ItemCarMobile className="card-interior">
                      <div className="img-container">
                        <img
                          src={IconInterior}
                          alt="Producto"
                          className="card-img"
                        />
                      </div>
                      <div className="info-box">
                        <p>
                          <span>Interior</span>
                          <br />
                          {producto.seatMaterial}
                        </p>
                      </div>
                    </ItemCarMobile>
                  </div>
                </div>
                <div className="garantia">
                  <img
                    src={IconGarantia}
                    alt="icon_garantia"
                    className="icon-img"
                  />
                  {`Garantía: ${producto.warranty}`}
                </div>
              </ItemsCarContainerMobile>
            </div>
            <div className="ficha-row">
              <FichaTecnica
                onClick={() =>
                  window.open(producto.multimedia.ficha_tec, "_blank")
                }
                disabled={
                  !producto.multimedia.ficha_tec ||
                  producto.multimedia.ficha_tec === ""
                }
              >
                Ver ficha técnica
              </FichaTecnica>
            </div>
            <div className="row">
              <div className="col-md-12 col-10">
                <CaracteristicasContainer>
                  <div className="title">
                    <p>Características del vehículo</p>
                  </div>
                  <div className="icons">
                    <div className="icon-div">
                      <img src={IconCar} alt="iconCar" />
                      <div>
                        <p>Tipo: {producto.category.name}</p>
                      </div>
                    </div>
                    <div className="icon-div">
                      <img src={IconTraccion} alt="iconTraccion" />
                      <div>
                        <p>Tracción: {producto.traction}</p>
                      </div>
                    </div>
                  </div>
                  <div className="setting">
                    <div className="icon_setting">
                      <img src={IconSetting} alt="iconSetting" />
                    </div>

                    <div>
                      <p>Accesorios adicionales: {producto.accessories}</p>
                    </div>
                  </div>
                </CaracteristicasContainer>
              </div>
            </div>
          </div>
          <div className="offset-1 col-md-4">
            <AsideProduct0km
              setRelatedColor={setRelatedColor}
              handleFinanciacionClick={handleFinanciacionClick}
              handleCotizadorClick={handleCotizadorClick}
              handleAsesorClick={handleAsesorClick}
              handleMeInteresaClick={handleMeInteresaClick}
              producto={producto}
              formData={formData}
              // transmissionId={productoTransmissionId}
              versionId={productoVersionId}
              versionsBack={versionsBack}
            />
          </div>
        </div>
        <div>
          <div className="banner col-md-12">
            <img alt="bannerimg" src={BannerImg} />
          </div>
        </div>
      </div>
      <div>
        <AsideProduct0kmMobile
          setRelatedColor={setRelatedColor}
          handleFinanciacionClick={handleFinanciacionClick}
          handleCotizadorClick={handleCotizadorClick}
          handleAsesorClick={handleAsesorClick}
          handleMeInteresaClick={handleMeInteresaClick}
          producto={producto}
          formData={formData}
          // transmissionId={productoTransmissionId}
          versionId={productoVersionId}
          versionsBack={versionsBack}
        />
      </div>
      <WhatsAppBtn />
      <Footer />
    </Container>
  );
};

export default Producto0km;
